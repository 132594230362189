import type { Track } from "~/types/audio"

export const useNavigatorMeta = (track: Track, subject: string) => {
  let blobURL: any = null
  const image = new Image()
  image.crossOrigin = "anonymous"
  image.src = track.img || ""

  image.onload = () => {
    const canvas = document.createElement("canvas")
    canvas.width = 128
    canvas.height = 128
    const context = canvas.getContext("2d")
    context?.drawImage(image, 0, 0, canvas.width, canvas.height)

    canvas.toBlob(blob => {
      if (!blob) return
      if (blobURL) URL.revokeObjectURL(blobURL)
      blobURL = URL.createObjectURL(blob)
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: track.title,
          // album does not work, have to use artist
          artist: subject,
          artwork: [
            {
              src: blobURL,
              sizes: `${canvas.width}x${canvas.height}`,
              type: blob.type,
            },
          ],
        })
      }
    })
  }
}
