<script setup lang="ts">
import { ContentProductPageBlocks } from "~/models/Content/ContentProductPageBlocks"
const data = useProductVariantsStore()
const productBlocks = ref<ContentProductPageBlocks[] | undefined>()

watchEffect(() => {
  if (data.product && data.marketingData) {
    productBlocks.value =
      data.marketingData[data.product]?.productPageBlocks
  }
})
const heroSectionMounted = ref(false)
const style = getComputedStyle(document.body)
style.getPropertyValue("--theme-loaded")
</script>

<template>
  <div
    class="flex h-full w-full flex-col items-center justify-center gap-4 pb-40 text-xl text-[--theme-darkest] lg:gap-20"
  >
    <UProductPageHero @mounted="heroSectionMounted = true" />
    <template v-if="heroSectionMounted">
      <UProductPageBlock
        v-for="productBlock in productBlocks"
        :key="productBlock.contentId"
        img-text-color="white"
        :product-block-data="productBlock"
      />
    </template>
  </div>
</template>

<route lang="yaml">
name: productPage
meta:
  layout: productPage
</route>
