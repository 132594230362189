import {
  ProductsMap,
  ProductVariants,
} from "~/models/Content/ContentProducts"
import * as Sentry from "@sentry/vue"
import { EcommerceProduct } from "~/types/productData"
import { ContentProductPages } from "~/models/Content/ContentProductPages"
import { sanitizeCurriculum, formatList } from "~/utilities/format"
import { variantFullNameMap } from "~/utilities/constants"
import { formatForURL } from "~/composables/composables"

export const getGroupedProductVariants = (
  productVariantsData: ProductVariants[],
  ecommerceData: EcommerceProduct[],
  marketingData: ContentProductPages | null,
): ProductsMap | undefined => {
  const productVariants: ProductsMap = {}
  for (const product of productVariantsData) {
    const variantTitle = product.ibexaTitle

    const words = variantTitle.split(" ")

    if (words.length < 2) {
      Sentry.captureException(
        "Wrong structure in product variant title, expected title to be at least two words on the form [productTitle + productVariant].",
      )

      console.error(
        "Wrong structure in product variant title, expected title to be at least two words on the form [productTitle + productVariant].",
      )
      console.error("Product Title Found: ", product.ibexaTitle)
      continue
    }

    // The variant (ex. "UiO" or "NTNU") is the last word in the title
    const variant = words[words.length - 1]
    const productTitle = variantTitle.replace(` ${variant}`, "")

    const productKey = buildProductKey(product.ibexaTitle)

    const productIsFree = computed(
      () =>
        ecommerceData.filter(p => p.ean === product.ean)[0]?.price ===
        0,
    )

    const variantCurriculumList =
      product.pensumFor.length > 0
        ? sanitizeCurriculum(product.pensumFor).split(",")
        : [variantFullNameMap[variant.toLowerCase()] ?? variant]

    const variantCurriculumLabel = formatList(variantCurriculumList)

    if (!productVariants[productKey]) {
      productVariants[productKey] = {
        productTitle,
        productTitleHtml: product.titleHtml,
        urlFormatProductTitle: formatForURL(productTitle),
        hasAtLeastOneReleasedProduct: product.isReleased,
        theme: marketingData?.[productKey]?.theme,
        header: marketingData?.[productKey]?.header?.html5,
        groupSalesPitch:
          marketingData?.[productKey]?.groupSalesPitch?.html5,
        productCurriculumFor: variantCurriculumList,
        variants: [
          {
            name: variantTitle,
            shortTitle: product.shortTitle,
            titleHtml: product.titleHtml,
            title: product.title,
            ean: product.ean,
            curriculumFor: variantCurriculumLabel,
            isReleased: product.isReleased,
            isFree: productIsFree.value,
            variantKey: variant.toLowerCase(),
            shortVariantLabel: variant,
            locationId: product._location.id,
            pathString: product._location.pathString,
          },
        ],
      }
    } else {
      productVariants[productKey].productCurriculumFor = [
        ...productVariants[productKey].productCurriculumFor,
        ...variantCurriculumList,
      ]
      productVariants[productKey].variants.push({
        name: variantTitle,
        shortTitle: product.shortTitle,
        titleHtml: product.titleHtml,
        title: product.title,
        ean: product.ean,

        curriculumFor: variantCurriculumLabel,
        isReleased: product.isReleased,
        isFree: productIsFree.value,
        variantKey: variant.toLowerCase(),
        shortVariantLabel: variant,
        locationId: product._location.id,
        pathString: product._location.pathString,
      })
    }
    if (
      productVariants[productKey] &&
      !productVariants[productKey].hasAtLeastOneReleasedProduct &&
      product.isReleased
    ) {
      productVariants[productKey].hasAtLeastOneReleasedProduct = true
    }

    if (
      product.titleHtml &&
      !productVariants[productKey].productTitleHtml
    ) {
      productVariants[productKey].productTitleHtml = product.titleHtml
    }
  }

  return Object.keys(productVariants).length > 0
    ? productVariants
    : undefined
}

function buildProductKey(ibexaTitle: string) {
  // We only want the part after '/' and before the last '-' in the URL, ex. '/kunne/exphil-uio' -> 'exphil'

  const segments = ibexaTitle.split(" ")
  return formatForURL(
    segments.slice(0, segments.length - 1).join("-"),
  )
}
