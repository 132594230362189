<script setup lang="ts">
import type { PropType } from "vue"

const props = defineProps({
  radius: {
    type: Number as PropType<number>,
    default: 30,
  },
  progress: {
    type: Number as PropType<number>,
    default: 10,
  },
  stroke: {
    type: Number as PropType<number>,
    default: 4,
  },
  strokeColor: {
    type: String as PropType<string>,
    default: "white",
  },
})

const normalizedRadius = props.radius - props.stroke * 2
const circumference = normalizedRadius * 2 * Math.PI

const strokeDashoffset = computed(() => {
  return circumference - (props.progress / 100) * circumference
})
</script>

<template>
  <svg aria-hidden="true" :height="radius * 2" :width="radius * 2">
    <circle
      stroke="white"
      :stroke-width="stroke / 2"
      fill="transparent"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
    <circle
      :stroke="strokeColor"
      :stroke-dasharray="`${circumference} ${circumference}`"
      :style="{ strokeDashoffset }"
      :stroke-width="stroke"
      fill="transparent"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
  </svg>
</template>

<style lang="scss" scoped>
circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
