<script setup lang="ts">
import type { PropType } from "vue"
import type { Subject } from "~/types/subject"
import { getColorClassNameFromScheme } from "~/utilities/colors"

const props = defineProps({
  subject: {
    type: Object as PropType<Subject>,
    required: true,
    default: () => ({}),
  },
  licence: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const withImage = ref(!!props.subject.img?.src)

const cardImage = () => {
  if (!withImage.value)
    return "background-image: linear-gradient(to bottom, var(--theme-dark), transparent); background-repeat: no-repeat; background-size: cover;"
  return `background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0), rgba(18, 11, 43, 0.90)), url(${props.subject.img?.src}); background-repeat: no-repeat; background-size: cover; background-position-y: center;`
}

const price = useProductPrice()

const backgroundVal = withImage.value
  ? ""
  : getColorClassNameFromScheme(props.subject.colorTag as string, [
    "bg",
  ])
const textVal = withImage.value
  ? ""
  : getColorClassNameFromScheme(props.subject.colorTag as string, [
    "text",
  ])
</script>

<template>
  <div
    class="flex w-full items-center rounded-lg shadow-[-8px_12px_8px_-6px_grey,-12px_14px_0px_-1px_var(--theme-lightest)] duration-200 hover:translate-x-[5px] hover:translate-y-[-5px] hover:shadow-[-10px_14px_8px_-6px_grey,-17px_19px_0px_-1px_var(--theme-lightest)]"
    :class="backgroundVal"
    :style="cardImage()"
  >
    <router-link
      :id="`subject-card-${subject.id}`"
      data-pendo="subject-card"
      :to="`/produkter/${
        subject.href.split('/')[1].replace(/-(?!.*-)/, '/') +
        '/' +
        subject.href.split('/')[2]
      }`"
      class="z-20 flex h-[350px] w-full items-end justify-start overflow-hidden rounded-lg duration-150 ease-linear md:hover:translate-x-[5px] md:hover:translate-y-[-3px] lg:h-[537px] lg:justify-end lg:rounded-xl lg:px-[50px] lg:py-[50px] <md:font-medium"
    >
      <div class="flex w-72 flex-col items-start p-4 lg:p-0">
        <div
          class="flex items-end text-white"
          :class="
            'border-' +
            textVal.split('-')[1] +
            '-' +
            textVal.split('-')[2]
          "
        >
          <div class="subject-slide-title mb-2" :class="textVal">
            <span
              v-if="
                subject.totalChildren !== 0 &&
                !licence &&
                price &&
                price > 0
              "
              class="freemium"
            >
              Prøvekapittel!
            </span>
            <span>{{
              +subject.priority + ". " + subject.title
            }}</span>
          </div>
        </div>

        <u-media-types
          :text-color="textVal"
          :data="subject"
          :licence="licence"
        />
      </div>
    </router-link>
  </div>
</template>

<style scoped lang="postcss">
.subject-slide-title {
  @apply font-national2compressed text-[32px] font-medium leading-10;
}
</style>
