import { unreachable } from "./errors"

type ColorTypes = (
  | "text"
  | "bg"
  | "shadowLeft"
  | "shadowBottom"
  | "color"
)[]

interface GetColorOptions {
  /** Sets the text color to black or white depending on the bg color instead of colored */
  bwText?: boolean
}

export const colorSchemes = new Map([
  [
    "britt",
    {
      text: "red-40",
      bwText: "white",
      bg: "dark-blue-dark",
      shadowLeft: "#1E429A",
      shadowBottom: "#16306F",
    },
  ],
  [
    "arne",
    {
      text: "dark-blue-medium",
      bwText: "black",
      bg: "yellow-40",
      shadowLeft: "#FFF195",
      shadowBottom: "#E8D346",
    },
  ],
  [
    "jakob",
    {
      text: "teal-lighter",
      bwText: "white",
      bg: "dark-blue-darker",
      shadowLeft: "#192F67",
      shadowBottom: "#0F1C3E",
    },
  ],
  [
    "tom",
    {
      text: "dark-blue-dark",
      bwText: "black",
      bg: "grey-40",
      shadowLeft: "#CCC8C2",
      shadowBottom: "#B0AAA1",
    },
  ],
  [
    "kjersti",
    {
      text: "yellow-lighter",
      bwText: "white",
      bg: "yellow-darker",
      shadowLeft: "#B98209",
      shadowBottom: "#886007",
    },
  ],
  [
    "asle",
    {
      text: "light-blue-darker",
      bwText: "black",
      bg: "teal-lighter",
      shadowLeft: "#D2F4F3",
      shadowBottom: "#ADEBEA",
    },
  ],
  [
    "silje",
    {
      text: "yellow-lighter",
      bwText: "white",
      bg: "light-blue-darker",
      shadowLeft: "#0043A8",
      shadowBottom: "#002F75",
    },
  ],
  [
    "karina",
    {
      text: "purple-dark",
      bwText: "black",
      bg: "yellow-dark",
      shadowLeft: "#F7CE41",
      shadowBottom: "#BE9815",
    },
  ],
  [
    "inger",
    {
      text: "green-lighter",
      bwText: "white",
      bg: "dark-blue-dark",
      shadowLeft: "#1E429A",
      shadowBottom: "#16306F",
    },
  ],
  [
    "svein",
    {
      text: "red-dark",
      bwText: "black",
      bg: "grey-lighter",
      shadowLeft: "#F6F5F3",
      shadowBottom: "#DFD9D2",
    },
  ],
  [
    "per",
    {
      text: "purple-dark",
      bwText: "black",
      bg: "purple-lighter",
      shadowLeft: "#F1DAEC",
      shadowBottom: "#E2B6DA",
    },
  ],
  [
    "sandra",
    {
      text: "teal-dark",
      bwText: "black",
      bg: "purple-lighter",
      shadowLeft: "#F1DAEC",
      shadowBottom: "#E2B6DA",
    },
  ],
  [
    "thor",
    {
      text: "purple-lighter",
      bwText: "white",
      bg: "dark-blue-dark",
      shadowLeft: "#1E429A",
      shadowBottom: "#16306F",
    },
  ],
  [
    "marit",
    {
      text: "red-lighter",
      bwText: "white",
      bg: "red-dark",
      shadowLeft: "#D63114",
      shadowBottom: "#9E240F",
    },
  ],
  [
    "hugo",
    {
      text: "red-dark",
      bwText: "black",
      bg: "purple-lightest",
      shadowLeft: "#FEFBFD",
      shadowBottom: "#F4E1F0",
    },
  ],
  [
    "hans",
    {
      text: "green-darker",
      bwText: "black",
      bg: "red-40",
      shadowLeft: "#FFB5A4",
      shadowBottom: "#D8998A",
    },
  ],
  [
    "ellen",
    {
      text: "red-lighter",
      bwText: "white",
      bg: "green-darker",
      shadowLeft: "#324336",
      shadowBottom: "#1C261E",
    },
  ],
  [
    "knut",
    {
      text: "yellow-lightest",
      bwText: "white",
      bg: "red-darker",
      shadowLeft: "#922311",
      shadowBottom: "#65180C",
    },
  ],
  [
    "viggo",
    {
      text: "green-darker",
      bwText: "black",
      bg: "yellow-medium",
      shadowLeft: "#FFE54D",
      shadowBottom: "#E5C400",
    },
  ],
  [
    "tore",
    {
      text: "teal-40",
      bwText: "white",
      bg: "dark-blue-dark",
      shadowLeft: "#1E429A",
      shadowBottom: "#16306F",
    },
  ],
  [
    "ruth",
    {
      text: "yellow-lighter",
      bwText: "white",
      bg: "grey-dark",
      shadowLeft: "#726A5A",
      shadowBottom: "#554F44",
    },
  ],
  [
    "gro",
    {
      text: "yellow-lighter",
      bwText: "white",
      bg: "teal-dark",
      shadowLeft: "#229190",
      shadowBottom: "#186767",
    },
  ],
  [
    "randi",
    {
      text: "teal-lightest",
      bwText: "white",
      bg: "dark-blue-medium",
      shadowLeft: "#2557D0",
      shadowBottom: "#1B419C",
    },
  ],
  [
    "synne",
    {
      text: "yellow-lightest",
      bwText: "white",
      bg: "green-medium",
      shadowLeft: "#4BA05A",
      shadowBottom: "#397A45",
    },
  ],
  [
    "philip",
    {
      text: "light-blue-dark",
      bwText: "black",
      bg: "green-lighter",
      shadowLeft: "#E1F5E4",
      shadowBottom: "#ACE2B5",
    },
  ],
  [
    "kirsti",
    {
      text: "yellow-lighter",
      bwText: "white",
      bg: "green-darker",
      shadowLeft: "#324336",
      shadowBottom: "#1C261E",
    },
  ],
  [
    "gerda",
    {
      text: "dark-blue-medium",
      bwText: "black",
      bg: "teal-lightest",
      shadowLeft: "#F7FDFD",
      shadowBottom: "#D6F5F3",
    },
  ],
  [
    "tobias",
    {
      text: "yellow-lightest",
      bwText: "white",
      bg: "purple-darker",
      shadowLeft: "#60205C",
      shadowBottom: "#3A1337",
    },
  ],
  [
    "berit",
    {
      text: "purple-lighter",
      bwText: "white",
      bg: "purple-dark",
      shadowLeft: "#95378B",
      shadowBottom: "#702969",
    },
  ],
  [
    "ida",
    {
      text: "dark-blue-medium",
      bwText: "black",
      bg: "red-lightest",
      shadowLeft: "#FEFBFB",
      shadowBottom: "#F6DDDA",
    },
  ],
  [
    "bjarne",
    {
      text: "black",
      bwText: "black",
      bg: "white",
      shadowLeft: "white",
      shadowBottom: "white",
    },
  ],
])

export function getColorClassNameFromScheme(
  name: string,
  types: ColorTypes,
  options: GetColorOptions = {},
) {
  if (!name) return ""

  function build({
    text,
    bwText,
    bg,
    shadowLeft,
    shadowBottom,
  }: {
    text: string
    bwText: string
    bg: string
    shadowLeft: string
    shadowBottom: string
  }) {
    return types
      .map(type => {
        switch (type) {
          case "text":
            return options.bwText ? `text-${bwText}` : `text-${text}`
          case "bg":
            return `bg-${bg}`
          case "color":
            return `--color-${text}`
          case "shadowLeft":
            return shadowLeft
          case "shadowBottom":
            return shadowBottom
          default:
            return unreachable(type)
        }
      })
      .join(" ")
  }

  return build(
    colorSchemes.get(name.toLowerCase()) ?? {
      text: "black",
      bwText: "black",
      bg: "white",
      shadowLeft: "white",
      shadowBottom: "white",
    },
  )
}
