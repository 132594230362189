<script setup lang="ts">
import { computed, ref } from "vue"
import { KsSkeleton, KsSkeletonWrapper } from "@aschehoug/kloss"
import "@aschehoug/kloss/dist/index.css"
import { isAuthorized } from "~/utilities/auth"
import { checkoutPageUrl } from "~/utilities/urls"

const route = useRoute<"product">()
const productParam = computed(() => route.params.product)
const showVariantModal = ref(false)
const products = useProductVariantsStore()
const groupedProductVariants = products.product
  ? products.groupedProductVariants?.[products.product]
  : undefined

const hasValidLicence = computed(() => {
  if (products.activeVariant) {
    return products.activeVariant?.userHasLicence
  } else {
    return products.productVariants.some(p => p.userHasLicence)
  }
})

const productInformation = useProductVariantsStore()

// Get product from ibexa
const ibexaProductEAN = computed(() => {
  const foundProduct = products?.productsList?.find(product =>
    product.productKey.includes(route.params.product),
  )

  return foundProduct ? [foundProduct] : []
})

//Ibexa product filtered by EAN
const productData = computed(() => {
  const ibexaProduct = ibexaProductEAN.value?.[0]
  const ean = ibexaProduct?.ean
  return ean
    ? groupedProductVariants?.variants.find(v => v.ean?.includes(ean))
    : undefined
})

//Different product states
const productReleased = computed(() => {
  if (products.activeVariant) {
    return products.activeVariant.isReleased
  } else {
    return products.productVariants.some(p => p.isReleased)
  }
})

const productIsFree = computed(() => {
  if (products.activeVariant) {
    return (
      products.activeVariant.isReleased &&
      products.activeVariant.isFree
    )
  } else {
    return false
  }
})

const getProductImage = () => {
  return productInformation.activeProduct?.variants[0]?.eCommerce
    ?.imageUrl
}

const licenceImgDesc = computed(() => {
  const productVariant = productData.value
  return productVariant?.eCommerce?.imageDescription
})
const windowAvailWidth = ref(window.screen.availWidth)

const isSmall = computed(() => windowAvailWidth.value <= 744)

const router = useRouter()
function goToProductContentButtonHandler() {
  if (products.activeVariant) {
    router.push(
      `/produkter/${products.activeProduct?.productKey ?? "exphil"}/${
        products.activeVariant.variantKey
      }`,
    )
  } else {
    showVariantModal.value = true
  }
}

window.addEventListener("resize", () => {
  windowAvailWidth.value = window.screen.availWidth
})

const imgTimeout = ref(false)
setTimeout(() => {
  if (!imgTimeout.value) {
    imgTimeout.value = true
  }
}, 5000)

const emit = defineEmits(["mounted"])
onMounted(() => {
  emit("mounted")
})
</script>

<template>
  <section class="flex w-full items-center justify-center">
    <u-modal
      v-model="showVariantModal"
      @close="showVariantModal = false"
    >
      <template #header>
        <h1
          class="bold font-national2condensed text-h-1-national-2-c text-dark-blue-80"
        >
          Hvor studerer du?
        </h1>
      </template>
      <div class="flex flex-col gap-2">
        <UButtonNew
          v-for="variant in products.activeProduct?.variants.filter(
            v => v.isReleased,
          )"
          :key="variant.shortVariantLabel"
          class="mb-2"
          :to="`/produkter/${products.activeProduct?.productKey}/${variant.variantKey}`"
          size="md"
          theme="cta"
          >{{
            variant.curriculumFor.replaceAll(" og ", " eller ")
          }}</UButtonNew
        >
      </div>
      Finner du ikke ditt studiested?
      <a
        class="underline"
        href="https://om.kunne.universitetsforlaget.no/hjelpesider/kb-tickets/new"
        >Kontakt oss</a
      >
    </u-modal>
    <div class="grid-cols-2 gap-20 md:grid">
      <figure
        class="order-2 flex h-fit items-center justify-center md:w-full lg:max-w-[40vw]"
      >
        <img
          v-if="getProductImage()"
          loading="eager"
          :src="getProductImage()"
          class="h-full max-h-[35em] w-fit max-w-full rounded-lg object-contain lg:max-h-full"
          :class="{
            'rounded-2xl': !getProductImage(),
          }"
          :alt="licenceImgDesc"
        />
        <KsSkeletonWrapper
          v-else-if="!imgTimeout"
          class="skeleton-wrapper h-full w-full rounded-2xl bg-[--theme-lightest]"
        >
          <KsSkeleton class="lg:max-w-[50rem]" height="34em" />
        </KsSkeletonWrapper>
      </figure>
      <div
        class="flex flex-col items-center justify-center gap-8 px-4 lg:items-start lg:px-0"
      >
        <h2
          v-if="!isFeatureEnabled"
          class="flex items-center justify-center font-numeraWebRegular text-marketing-mobile-h2-h2-caption text-[--theme-darkest] md:text-marketing-desktop-h2-h2-caption"
          v-html="products.activeProduct?.header"
        />
        <h2
          v-if="
            isFeatureEnabled &&
            products.activeProduct?.productKey !==
              'matematikk-for-oekonomer'
          "
          class="flex items-center justify-center font-numeraWebRegular text-marketing-mobile-h2-h2-caption text-[--theme-darkest] md:text-marketing-desktop-h2-h2-caption"
          v-html="products.activeProduct?.header"
        />

        <div
          class="flex w-full items-center justify-start gap-2 lg:w-fit"
        >
          <UButtonNew
            v-if="productReleased"
            size="md"
            data-pendo="produktforside-prov-gratis"
            :theme="
              (isAuthorized && hasValidLicence) || productIsFree
                ? 'cta'
                : 'dark'
            "
            :pill="(isAuthorized && hasValidLicence) || productIsFree"
            :variant="
              !hasValidLicence && !productIsFree
                ? 'outline'
                : 'filled'
            "
            @click="goToProductContentButtonHandler"
          >
            {{ !isAuthorized ? "Prøv gratis" : "Til produktet" }}
          </UButtonNew>
          <UButtonNew
            v-if="
              !hasValidLicence && productReleased && !productIsFree
            "
            pill
            theme="cta"
            :to="checkoutPageUrl(productParam)"
            size="md"
            variant="filled"
            data-pendo="produktforside-kjop-tilgang"
          >
            {{ isSmall ? "Kjøp" : "Kjøp tilgang" }}
          </UButtonNew>
          <h3
            v-if="
              !productReleased &&
              (!isFeatureEnabled ||
                products.activeProduct?.productKey !==
                  'matematikk-for-oekonomer')
            "
            class="font-national2condensed text-4xl font-medium text-[--theme-dark]"
          >
            Produktet lanseres snart, da kan du prøve oss helt gratis
          </h3>
          <div
            v-if="
              isFeatureEnabled &&
              !productReleased &&
              products.activeProduct?.productKey ===
                'matematikk-for-oekonomer'
            "
            class="flex flex-col gap-4"
          >
            <h2
              class="font-national2condensed text-marketing-mobile-h2-h2-bold font-extrabold text-[--theme-dark] md:text-marketing-desktop-h2-h2-bold"
            >
              Produktet kommer snart
            </h2>
            <h3
              class="flex items-center justify-center font-numeraWebRegular text-marketing-mobile-h2-h2-caption text-[--theme-darkest] md:text-marketing-desktop-h2-h2-caption"
            >
              Registrer interesse og bli blant de første som får
              tilgang når produktet er lansert.
            </h3>
            <UButtonNew
              class="mt-8"
              :href="`/produkter/${products.activeProduct?.productKey}/registrer-interesse`"
              theme="cta"
              pill
              variant="filled"
              >Hold meg oppdatert</UButtonNew
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang=""></style>
