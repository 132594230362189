export function useChapterProgress() {
  const progressStore = usePersonalizationStore()
  const productContentStore = useProductContentStore()

  const lastArticleInChapter: ComputedRef<boolean> = computed(() => {
    const currentArticle = productContentStore.activeArticle
    const articles = productContentStore.articles

    if (!currentArticle) return false

    const currentArticleIndex = articles.findIndex(
      article => article.id === currentArticle.id,
    )

    // Check if this is the last article in the current chapter
    return currentArticleIndex === articles.length - 1
  })

  const chapterCompleted: ComputedRef<boolean> = computed(() => {
    const articles = productContentStore.articles
    return articles.every(article =>
      progressStore.readHistory.some(
        readArticle =>
          readArticle.id === article.id && readArticle.done,
      ),
    )
  })

  const chapterProgress: ComputedRef<[string, string]> = computed(
    () => {
      const articles = productContentStore.articles
      const completedArticlesCount = articles.filter(article =>
        progressStore.readHistory.some(
          readArticle =>
            readArticle.id === article.id && readArticle.done,
        ),
      ).length

      const totalArticlesCount = articles.length

      return [
        completedArticlesCount.toString(),
        totalArticlesCount.toString(),
      ]
    },
  )

  return {
    lastArticleInChapter,
    chapterCompleted,
    chapterProgress,
  }
}
