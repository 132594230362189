<script setup lang="ts">
const products = useProductContentStore()

const personalization = usePersonalizationStore()

const articles = computed(() => {
  return products.articles
})

onMounted(() => {
  if (personalization.lastActiveArticle) {
    const id = personalization.lastActiveArticle.id
    const element = document.getElementById(id.toString())
    if (element) {
      setTimeout(() => {
        element.scrollIntoView()
        window.scrollBy(0, -200)
      }, 100)
    }
  }
})
</script>

<template>
  <nav
    class="w-full max-w-[70rem] pb-32 pt-[1.5em] md:w-[21rem] lg:w-full xl:pt-[2.5em]"
  >
    <ul
      v-if="articles.length"
      id="article-cards"
      :key="0"
      class="flex flex-col gap-10"
    >
      <li v-for="(article, key) in articles" :key="key">
        <router-link
          data-pendo="article-card"
          :to="`/produkter/${
            article.href.split('/')[1].replace(/-(?!.*-)/, '/') +
            '/' +
            article.href.split('/')[2] +
            '/' +
            article.href.split('/')[3]
          }`"
          class="mx-auto flex h-[300px] w-full max-w-[350px] flex-col gap-3 transition-transform duration-150 ease-linear md:w-full md:max-w-none md:hover:scale-[101%] lg:h-[500px]"
          :class="{ ['pointer-events-none']: article.restricted }"
        >
          <UPreview :article="article" />
        </router-link>
      </li>
    </ul>
    <u-skeleton-card-list v-else :key="1" />
  </nav>
</template>

<route lang="yaml">
name: subject
meta:
  layout: subject
</route>
