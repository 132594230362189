import { getCheckoutStatus } from "~/hooks/payment/"
import { useProductVariantsStore } from "../store/productVariants"

export function useLicencePolling(
  duaration: number,
  interval = 2000,
) {
  const rejected = ref(false)
  const data = ref(false)
  const licence = useLicenceStore()
  const isLoading = ref(false)

  const productVariantsStore = useProductVariantsStore()

  let timerId: any

  const stopPolling = () => {
    clearInterval(timerId)
    isLoading.value = false
  }

  const fetchData = async () => {
    isLoading.value = true
    if (!productVariantsStore.activeVariant?.userHasLicence) {
      await licence.setLicenceData()
    }

    if (
      productVariantsStore.activeVariant?.ean &&
      licence.hasLicenceByEan(productVariantsStore.activeVariant?.ean)
    ) {
      data.value = true

      stopPolling()
    } else {
      data.value = false
    }
    const checkoutStatus = await getCheckoutStatus()
    if (checkoutStatus?.sessionStatus === "REJECTED") {
      rejected.value = true
      stopPolling()
    }
  }

  const startPolling = () => {
    fetchData()
    timerId = setInterval(fetchData, interval)
  }
  onMounted(() => {
    startPolling()
    setTimeout(() => {
      stopPolling()
    }, duaration)
  })

  return {
    data,
    isLoading,
    rejected,
  }
}
