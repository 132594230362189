const currencyFormater = Intl.NumberFormat("nb", {
  style: "currency",
  currency: "NOK",
  currencyDisplay: "narrowSymbol",
})

const dateFormater = Intl.DateTimeFormat("nb", {
  month: "long",
  day: "numeric",
})

export function formatCurrency(amount: number) {
  return currencyFormater
    .formatToParts(amount)
    .filter(({ type }) => type !== "currency")
    .map(({ type, value }) =>
      type === "fraction" && value === "00" ? "-" : value,
    )
    .join("")
}

export function formatDate(
  date: Date,
  options?: Intl.DateTimeFormatOptions,
) {
  if (options !== null) {
    return Intl.DateTimeFormat("nb", options).format(date)
  }

  // If no options are provided, reuse the default formatter to prevent creating new formatters.
  return dateFormater.format(date)
}

export function formatYearRange(from: number, to?: number | null) {
  const { abs } = Math
  const beforeEra = "fvt."
  const afterEra = "evt."

  if (to === null || to === undefined) {
    return from < 0 ? `${abs(from)} ${beforeEra}` : `${from}`
  }

  if (to < 0 && from < 0) {
    return `${abs(from)}-${abs(to)} ${beforeEra}`
  }

  if (to < 0 && from >= 0) {
    return `${abs(from)} ${beforeEra} - ${to} ${afterEra}`
  }

  return `${from}-${to}`
}

export const sanitizeCurriculum = (text: string) => {
  return text
    .replaceAll("og", ",")
    .replaceAll("eller", ",")
    .replaceAll(" ", "")
}

export const formatList = (items: string[]): string => {
  const formatter = new Intl.ListFormat("no", {
    style: "long",
    type: "conjunction",
  })

  return formatter.format(items)
}
