<script setup lang="ts">
import { Article, ArticleTemplate } from "../../../types/article"
const props = defineProps({
  title: {
    type: String,
    default: "Laster...",
  },
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const productContent = useProductContentStore()

const template: ArticleTemplate = props.data.template
</script>
<template>
  <div
    class="bg-platform-paper flex h-full w-full overflow-clip rounded-xl font-national2condensed text-black"
    :class="{
      'flex-row-reverse': template === 'UTextWithImageArticle',
      'brightness-[85%]': data.restricted,
    }"
  >
    <img
      v-if="data.img && data.img.src"
      class="h-full w-[50%] object-cover"
      :src="data.img?.src"
      :alt="data.img?.alt"
    />
    <div
      class="bg-platform-paper relative h-full w-full flex-col p-8"
      :class="{
        'max-w-[50%]': data.img && data.img.src,
        'px-8 lg:px-32': !data?.img?.src,
      }"
    >
      <h4
        class="text-md text-darker hidden pb-4 font-national2 font-extrabold uppercase lg:block lg:text-lg"
      >
        {{ productContent.activeSubject?.title }}
      </h4>
      <h3
        class="max-h-18 max-w-96 overflow-hidden truncate whitespace-pre-wrap pb-6 font-national2condensed text-2xl font-medium lg:text-4xl xl:text-5xl"
      >
        {{ title }}
      </h3>
      <URichText
        v-if="!data.restricted"
        inert
        :html="data.body?.html.trim()"
        class="h-full w-full overflow-clip whitespace-break-spaces lg:line-clamp-none [&_.text-link]:no-underline"
      />
      <span
        v-else
        class="text-dark font-national2 text-base font-bold lg:text-lg"
      >
        - Tilgang krever lisens</span
      >
      <div
        class="absolute bottom-0 left-0 h-full w-full bg-gradient-to-t via-transparent to-transparent"
      />
    </div>
  </div>
</template>
