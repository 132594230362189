import getMathTaskSolutionFormat, {
  AssignmentSolutions,
  FormattedMathTaskSolutions,
} from "~/hooks/math-task/index"
import * as Sentry from "@sentry/vue"
import { FasitSidenote } from "~/models/FasitSidenote"

export async function getMathTaskSolutions(articleId: number) {
  let data: AssignmentSolutions | undefined

  try {
    data = await getMathTaskSolutionFormat(articleId)
  } catch (e) {
    Sentry.captureException(
      `Missing suggested solutions for math tasks in article [id=${articleId}]`,
    )
  }

  if (!data) return undefined

  return data
}

export function formatSolutionsSidenote(
  assignments:
    | Map<number, FormattedMathTaskSolutions>
    | undefined = new Map<number, FormattedMathTaskSolutions>(),
): FasitSidenote | null {
  const solution: string[] = []

  for (const [, assignment] of assignments) {
    if (assignment.solution) {
      solution.push(assignment.solution.solutionText.html5)
    }
  }

  if (solution.length === 0) {
    return null
  }

  return {
    identifier: "solution",
    title: "Fasit",
    resourceUrl: "fasit",
    html: solution.join("<br>"),
  }
}

type MathTemplates = "UMathTaskWithImageArticle" | "UMathTaskArticle"

export function isMathTemplate(
  value: string,
): value is MathTemplates {
  return (
    value === "UMathTaskWithImageArticle" ||
    value === "UMathTaskArticle"
  )
}
