<script setup lang="ts">
import { ButtonState } from "./UAudioplayerNew.vue"
const props = defineProps({
  buttonState: {
    type: String as PropType<ButtonState>,
    default: "pause",
  },
  handlePlay: {
    type: Function as PropType<() => void>,
    default: undefined,
  },
  handleSkip: {
    type: Function as PropType<(value: number) => void>,
    default: undefined,
  },
  fullscreen: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})
</script>
<template>
  <div class="flex items-center justify-between gap-2 w-fit">
    <UButtonNew
      v-if="props.handleSkip"
      variant="quite"
      theme="white"
      class="scale-75 hover:text-lighter"
      :class="{ 'hidden lg:block': !props.fullscreen }"
      @click="() => props.handleSkip!(-15)"
    >
      <UIcon name="skip-15-back" size="xl" />
    </UButtonNew>
    <UButtonNew
      v-if="props.handlePlay"
      variant="outline"
      circle
      theme="light"
      @click="() => props.handlePlay!()"
    >
      <UIcon :name="props.buttonState" />
    </UButtonNew>
    <UButtonNew
      v-if="props.handleSkip"
      variant="quite"
      theme="white"
      class="scale-75 hover:text-lighter"
      :class="{ 'hidden lg:block': !props.fullscreen }"
      @click="() => props.handleSkip!(15)"
    >
      <UIcon name="skip-15-forward" size="xl" />
    </UButtonNew>
  </div>
</template>

<style lang=""></style>
