export function parseMathML(mathML: string, parser: DOMParser) {
  const parsed = parser.parseFromString(mathML, "text/html")
  removeMathMLStyle(parsed)

  return parsed.body.innerHTML
}

export function removeMathMLStyle(parsed: Document) {
  parsed.querySelectorAll("mstyle").forEach(el => {
    el.replaceWith(...Array.from(el.children))
  })
}
