<script setup lang="ts">
import { getMinutesAndSeconds } from "~/composables/getMinutesAndSeconds"
import { useNavigatorMeta } from "~/composables/useNavigatorMeta"
import UAudioPlaybackSpeed from "../Audio/UAudioPlaybackSpeed.vue"
const audioStore = useAudioStore()
const route = useRoute()

const audio = ref<HTMLAudioElement | null>(null)
const duration = ref(0)
const currentTime = ref(0)
const progress = ref(0)
const fullScreen = ref(false)
const audioState = ref("play")
const licence = useLicenceStore()
const licenceData = computed(() => licence.licenceData)
const { dataAssigned: licenceAssigned } = useDataWatch(licenceData)

const handlePreviousTrack = () => {
  const currentIndex = audioStore.playList.findIndex(i => i.active)
  const previousTrack = audioStore.playList[currentIndex - 1]
  if (previousTrack) {
    audioStore.setCurrentTrack(previousTrack.id)
  }
}

const handleNextTrack = () => {
  const currentIndex = audioStore.playList.findIndex(i => i.active)
  const nextTrack = audioStore.playList[currentIndex + 1]
  if (nextTrack) {
    audioStore.setCurrentTrack(nextTrack.id)
  }
}

const handleAudioPlay = () => {
  if (audioState.value === "pause") {
    audioState.value = "play-audio"
    audio.value?.pause()
  } else {
    audioState.value = "pause"
    audio.value?.play()
  }
}

const updateMeta = async () => {
  duration.value = audio.value?.duration ?? 0
  currentTime.value = audio.value?.currentTime ?? 0

  if (!audioStore.activePlayer) {
    audioStore.togglePlayer()
  }
  audioState.value = "pause"
  audio.value?.play()

  const currentIndex = audioStore.playList.findIndex(i => i.active)
  const track = audioStore.playList[currentIndex]
  useNavigatorMeta(track, audioStore.playListSubject?.title || "")

  navigator.mediaSession.setActionHandler(
    "previoustrack",
    handlePreviousTrack,
  )
  navigator.mediaSession.setActionHandler(
    "nexttrack",
    handleNextTrack,
  )
  navigator.mediaSession.setActionHandler("play", handleAudioPlay)
  navigator.mediaSession.setActionHandler("pause", handleAudioPlay)
}

const inAuthLayout = computed(() =>
  /product|subject|article/.test(String(route.meta?.layout)),
)
const inArticleLayout = computed(() =>
  /article/.test(String(route.meta?.layout)),
)
const currentTrack = computed(() =>
  audioStore.playList.find(i => i.active),
)

watch(inAuthLayout, () => {
  if (!inAuthLayout.value) {
    audioStore.activePlayer = false
  }
})

const setAsCurrentTrack = (id: number) => {
  audioStore.setCurrentTrack(id)
}

const handleSkip = (seconds: number) => {
  if (audio.value) {
    audio.value.currentTime += seconds
    currentTime.value = audio.value.currentTime
  }
}

const handleSpeed = (speed: number) => {
  if (audio.value) audio.value.playbackRate = speed
}

const onTimeUpdate = () => {
  if (audio.value) {
    currentTime.value = audio.value.currentTime
    progress.value = (currentTime.value * 100) / duration.value

    if (currentTime.value === duration.value) {
      audioState.value = "play"
      handleNextTrack()
    }
  }
}

const onPause = () => {
  audioState.value = "play"
}

const onPlay = () => {
  audioState.value = "pause"
}

const onSliderEvent = (t: number) => {
  if (audio.value) {
    audio.value.currentTime = t
    currentTime.value = audio.value.currentTime
  }
}
</script>

<template>
  <div class="flex items-center justify-center w-full">
    <div
      v-if="audioStore.activePlayer"
      class="audio-player"
      :class="[
        {
          '!bottom-0 !h-[100dvh] !w-full flex-col justify-between overflow-y-auto !rounded-none border-none !pb-12':
            fullScreen,
        },
        { '!bottom-3': !inArticleLayout && licenceAssigned },
      ]"
    >
      <div v-if="fullScreen" class="full-screen-wrapper">
        <u-button class="self-end" pill @click="fullScreen = false">
          <template #prepend>
            <u-icon name="close" size="xs" class="scale-[0.75]" />
          </template>
        </u-button>
        <div class="subject-wrapper" :class="{ '!pt-6': fullScreen }">
          <p>
            {{ audioStore.playListSubject?.priority }}.
            {{ audioStore.playListSubject?.title }}
          </p>
          <div>
            {{
              getMinutesAndSeconds(
                audioStore.playListSubject?.playTime,
              )
            }}
          </div>
        </div>
        <ul class="tracks-list">
          <li
            v-for="(i, key) in audioStore.playList"
            :id="`track-${key}`"
            :key="key"
          >
            <div
              class="txt-circle"
              :class="{ '!bg-platform-paper': i.active }"
            />
            <div class="track-txt">
              <button
                type="button"
                class="text-start hover:text-red-60"
                @click="setAsCurrentTrack(i.id)"
              >
                {{ i.title }}
              </button>
              <span>{{ getMinutesAndSeconds(i.duaration) }}</span>
            </div>
          </li>
        </ul>
      </div>
      <audio
        ref="audio"
        :src="currentTrack?.url"
        preload="metadata"
        @loadedmetadata="updateMeta"
        @timeupdate="onTimeUpdate"
        @pause="onPause"
        @play="onPlay"
      />
      <div
        class="compact text-platform-paper"
        :class="{
          'bottom-16 max-h-24 w-full max-w-[1024px] flex-col justify-end':
            fullScreen,
        }"
      >
        <div class="flex items-center justify-center gap-0 md:gap-4">
          <button
            type="button"
            class="audio-title-wrapper"
            @click="fullScreen = !fullScreen"
          >
            <p class="sub-heading-plaintext">
              {{ audioStore.playListSubject?.priority }}.
              {{ audioStore.playListSubject?.title }}
            </p>
            <p>{{ currentTrack?.title }}</p>
          </button>
          <button
            type="button"
            class="relative h-[48px] w-[48px]"
            @click="handleAudioPlay()"
          >
            <i
              class="all-unset relative left-[-0.5rem] top-[-0.6rem] block"
            >
              <u-progress-ring
                :progress="progress"
                :radius="32"
                stroke-color="var(--u-contrast)"
              />
            </i>
            <u-icon
              class="absolute left-[0.81rem] top-[0.70rem] fill-platform-paper text-platform-paper"
              :name="audioState"
              size="md"
            />
          </button>
          <u-button
            v-if="audioState === 'pause' || fullScreen"
            pill
            size="sm"
            variant="quite"
            :class="{ '!hidden md:inline': !fullScreen }"
            @click="handleSkip(-15)"
          >
            <template #prepend>
              <u-icon
                name="skip-15-back"
                size="md"
                class="scale-[1.5]"
              />
            </template>
          </u-button>

          <u-button
            v-if="audioState === 'pause' || fullScreen"
            variant="quite"
            size="sm"
            pill
            :class="{ '!hidden md:inline': !fullScreen }"
            @click="handleSkip(15)"
          >
            <template #prepend>
              <u-icon
                name="skip-15-forward"
                size="md"
                class="scale-[1.5]"
              />
            </template>
          </u-button>
        </div>
        <div
          v-if="audioState === 'pause' || fullScreen"
          class="items-center hidden gap-4 lg:flex"
          :class="[
            {
              '!flex w-full flex-row-reverse': fullScreen,
            },
            { 'hidden md:flex-1': !fullScreen },
          ]"
        >
          <UAudioPlaybackSpeed
            @speed-event="speed => handleSpeed(speed)"
          />
          <div
            class="seek-slider-wrapper"
            :class="{ 'xl:ml-8': !fullScreen }"
          >
            <div class="time min-w-[46px]">
              {{ getMinutesAndSeconds(currentTime) }}
            </div>
            <u-audio-slider
              :current-time="currentTime"
              :max="duration"
              @current-time-event="t => onSliderEvent(t)"
            />
            <div class="time">
              {{ getMinutesAndSeconds(duration) }}
            </div>
          </div>
        </div>
        <u-button
          v-show="!(fullScreen || audioState === 'pause')"
          class="stroke-paper text-platform-paper hover:stroke-red-60"
          pill
          @click="audioStore.togglePlayer()"
        >
          <template #prepend>
            <u-icon name="close" size="xs" class="scale-[0.75]" />
          </template>
        </u-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.audio-player {
  @apply fixed bottom-[110px] z-40 flex h-20 w-[98vw] items-center gap-4 rounded-xl bg-[--theme-dark] px-5 py-4;

  /* md */
  @apply md:pl-[69px] md:pr-[84px];

  /* lg */
  @apply lg:pl-[73px] lg:pr-[84px];

  /* xl */
  @apply xl:pl-[84px] xl:pr-[84px];

  /* 2xl */
  @apply 2xl:pl-[204px] 2xl:pr-[84px];

  transition:
    width 0.6s ease-in-out,
    height 0.45s ease-in-out,
    bottom 0.5s ease-in-out,
    border-radius 0.6s ease-in-out;
}

.compact {
  @apply flex flex-1 items-center justify-between gap-4;
}

.full-screen-wrapper {
  @apply flex w-full max-w-[1024px] flex-col font-national2 text-platform-paper;

  p,
  span,
  div {
    @apply font-national2 text-[18px] leading-normal;
  }
}

.subject-wrapper {
  @apply flex justify-end gap-4;
  p {
    @apply font-bold uppercase;
  }
}
.tracks-list {
  @apply w-full pt-5;
  li {
    @apply relative flex gap-4 py-4;
    .track-txt {
      @apply flex flex-1 items-center justify-between gap-2.5;
    }
    .txt-circle {
      outline-style: solid;
      @apply z-10 h-[24px]
      w-[24px]
      self-center rounded-full border-2 border-platform-paper bg-[--theme-darkest]
      outline-[--theme-darkest];
    }
    &:before {
      content: "";
      @apply absolute
      left-3
      top-8
      z-[-1]
      h-[100%]
      border-l-2
      border-solid
      border-platform-paper;
    }
    &:last-child {
      &:before {
        content: none;
      }
    }
  }
}

.audio-title-wrapper {
  @apply flex w-fit flex-wrap gap-0 self-center md:gap-3 xl:mr-8;
  p {
    @apply w-40
    overflow-hidden
    overflow-ellipsis
    whitespace-nowrap
    font-national2
    leading-normal
    text-platform-paper
    md:w-auto;

    &.sub-heading-plaintext {
      @apply font-bold uppercase;
    }
  }
}

.time {
  @apply font-national2 text-[18px] leading-normal text-platform-paper;
}

.seek-slider-wrapper {
  @apply flex flex-1 flex-nowrap items-center gap-2.5;
}
</style>
