<script setup lang="ts">
const productContent = useProductContentStore()
const products = useProductVariantsStore()
const route = useRoute<"subject">()
const licence = useLicenceStore()
const licenceData = computed(() => licence.licenceData)
const { dataAssigned: licenceAssigned } = useDataWatch(licenceData)

const subjectShortTitle = ref("")
const subjectPriority = ref("")

const maxWidth: string = "w-[90vw] md:w-[85vw] 2xl:w-[80vw] "

watch(
  () => productContent.activeSubject,
  () => {
    subjectShortTitle.value = productContent.activeSubject
      ?.shortTitle as string
    subjectPriority.value = productContent.activeSubject
      ?.priority as unknown as string
  },
)

const checkSubject = () =>
  (subjectPriority.value && subjectShortTitle.value) ||
  (productContent.activeSubject?.shortTitle &&
    productContent.activeSubject?.priority)

const folder = computed(() => {
  const matchingFolder = productContent.folders.find(p => {
    return p.id === productContent.activeSubject?.parentLocationId
  })
  return matchingFolder ? matchingFolder : null
})

const decodedFolderTitle = computed(() => {
  if (folder.value?.title) {
    return formatForURL(folder.value?.title)
  }
})
</script>

<template>
  <u-feature-flag
    class="fixed left-0 top-0 z-50 w-fit p-4 text-gray-400"
  >
    <UButtonNew to="/" variant="quite" size="sm" theme="white"
      >kunne.no</UButtonNew
    >
  </u-feature-flag>
  <div
    class="flex h-full w-full items-center justify-center bg-[--theme-darkest]"
  >
    <div
      class="flex flex-col items-center justify-center md:flex-row md:items-start md:gap-16"
      :class="maxWidth"
    >
      <u-side-nav
        :data="productContent.articleNav"
        :licence="licenceAssigned"
        :product="products.activeVariant"
        class="md:w-full"
      >
        <template #list-top>
          <li :key="0" class="mt-0 w-fit md:m-0 md:mt-1">
            <router-link
              :to="
                folder?.title
                  ? `/produkter/${route.params.product}/${route.params.variant}#${decodedFolderTitle}`
                  : `/produkter/${route.params.product}/${route.params.variant}`
              "
              class="flex items-center"
            >
              <u-icon name="arrow-left" size="sm" class="mr-3.5" />
              <h4
                class="max-w-[200px] truncate font-extrabold lg:max-w-[300px]"
              >
                Tilbake {{ folder ? `til ${folder.title}` : "" }}
              </h4>
            </router-link>
          </li>
          <li
            v-if="checkSubject()"
            class="mt-8 hidden items-start pb-3 text-white md:flex md:border-b-2 md:border-white md:pb-2"
          >
            <span
              class="subject-number menu-heading-large mr-3.5 flex w-[18px] items-center justify-center"
            >
              {{
                productContent.activeSubject?.priority ||
                subjectPriority
              }}
            </span>
            <span class="menu-heading-large">
              {{
                productContent.activeSubject?.shortTitle ||
                subjectShortTitle
              }}
            </span>
          </li>
        </template>
      </u-side-nav>
      <main
        class="h-full w-full max-w-[45em] <md:flex <md:flex-col <md:items-center <md:justify-center"
      >
        <UMyProfileButton
          :path="[
            route.params.product,
            route.params.variant,
            route.params.subject,
          ]"
        />
        <div
          v-if="productContent.noSubjectsFound"
          class="richtext pb-32 text-white md:pt-[175px] 2xl:pt-[210px]"
        >
          <h1>Fant ikke kapittelet</h1>
          <router-link
            class="mt-6 block"
            :to="`/produkter/${route.params.product}/${route.params.variant}`"
          >
            Ta meg til forsiden av Kunne
            {{
              capitalizeChar(
                products.activeProduct?.productTitle ?? "",
              )
            }}
          </router-link>
        </div>
        <template v-else>
          <h1
            v-if="
              checkSubject() && !route.fullPath.endsWith('min-side')
            "
            class="pb-7 pt-10 font-national2condensed text-[40px] font-medium text-white md:hidden"
          >
            {{
              `${
                productContent.activeSubject?.priority ||
                subjectPriority
              }. ${
                productContent.activeSubject?.shortTitle ||
                subjectShortTitle
              }`
            }}
          </h1>
          <router-view />
        </template>
      </main>
    </div>
    <UMarketingBanner />
  </div>
</template>

<style scoped>
.menu-heading-large {
  @apply font-national2 text-[20px] font-bold not-italic !leading-6;
}
</style>
