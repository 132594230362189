<script setup lang="ts">
const backgroundColor = useBackgroundColor()
backgroundColor.value = "var(--theme-lightest)"

const products = useProductVariantsStore()
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <div class="w-[90vw] md:w-[80vw]">
      <UHeader :product="products.activeProduct" />
      <main
        class="flex w-full items-center justify-center overflow-hidden"
      >
        <router-view />
      </main>
    </div>
  </div>
  <UMarketingFooter />
</template>
