<script setup lang="ts">
//@ts-expect-error: Supress components not used error
import { KsSkeleton, KsSkeletonWrapper } from "@aschehoug/kloss"
const props = defineProps({
  productBlockData: {
    type: Object,
    default: null,
  },
  imgTextColor: {
    type: String as PropType<"black" | "white">,
    default: "black",
  },
})

const productBlockContent = computed(() => {
  return {
    variant: props.productBlockData?.blockOrientation,
    textTop: props.productBlockData?.header,
    textBottom: props.productBlockData?.body,
    imgUrl: props.productBlockData?.image?.uri,
    imgText: props.productBlockData?.imageText,
    imgDesc: props.productBlockData?.image?.alternativeText,
  }
})

const isQuote = computed(() =>
  productBlockContent.value.variant?.includes("quote"),
)
const isAuthor = computed(() =>
  productBlockContent.value.variant?.includes("author"),
)
const imageFirst = computed(() =>
  productBlockContent.value.variant?.split("-")[0].includes("image"),
)
</script>

<template>
  <section
    :class="{
      'flex-row-reverse': !imageFirst,
      'flex-row': imageFirst,
    }"
    class="flex h-full w-full max-w-[90em] items-center justify-around gap-8 overflow-clip rounded-lg md:w-full md:gap-16 lg:max-h-[35em] xl:gap-20 <lg:flex-col"
  >
    <img
      v-if="productBlockData"
      :src="productBlockContent.imgUrl"
      class="relative h-full max-h-[35em] w-full rounded-lg object-cover lg:h-[20em] lg:max-h-full lg:w-[50em] lg:max-w-[50%] xl:h-[25em]"
      :alt="productBlockContent.imgDesc"
    />
    <KsSkeletonWrapper
      v-else
      class="skeleton-wrapper flex h-full gap-4"
    >
      <KsSkeleton height="35rem" />
    </KsSkeletonWrapper>
    <div
      v-if="productBlockData"
      class="flex h-fit w-full max-w-[41.5em] flex-col items-center justify-center gap-10 pt-2 lg:w-[50vw] lg:items-start"
    >
      <div
        class="flex w-full flex-col items-start justify-center sm:w-fit"
        :class="{
          '!flex-col items-center justify-start gap-10 lg:!flex-row':
            isAuthor,
        }"
      >
        <div
          class="flex h-full w-full flex-col items-start justify-center"
          :class="{
            'items-center': isAuthor,
          }"
        >
          <h4
            class="ellpisis flex max-h-32 w-full justify-start overflow-clip px-8 font-national2condensed text-4xl font-extrabold lg:px-0 lg:text-5xl 2xl:text-6xl 2xl:leading-[4rem]"
            :class="{
              'max-h-52 font-semibold normal-case italic': isQuote,
            }"
          >
            {{ productBlockContent.textTop }}
          </h4>
          <img
            v-if="isAuthor"
            src="../../assets/images/authorImage1.png"
            class="h-fit w-full max-w-[10em] px-4 pt-6 sm:hidden lg:max-w-[20em] lg:px-0"
            :alt="productBlockContent.imgDesc"
          />
          <p
            class="line-clamp-12 w-fit text-pretty px-8 pt-4 font-numeraWebRegular text-xl font-light leading-normal tracking-wider text-[--theme-darkest] lg:px-0 lg:text-2xl 2xl:line-clamp-8 2xl:text-3xl 2xl:leading-relaxed"
          >
            {{ productBlockContent.textBottom }}
          </p>
        </div>
        <img
          v-if="isAuthor"
          src="../../assets/images/authorImage1.png"
          class="hidden h-full w-fit min-w-[8em] max-w-[10em] px-4 lg:visible lg:max-w-[11em] lg:px-0"
          :alt="productBlockContent.imgDesc"
        />
      </div>
    </div>
    <KsSkeletonWrapper
      v-else
      class="skeleton-wrapper flex h-full flex-col gap-4"
    >
      <KsSkeleton width="60%" height="5rem" class="mb-4" />
      <KsSkeleton height="3.5rem" />
      <KsSkeleton height="3.5rem" />
      <KsSkeleton width="90%" height="3.5rem" />
    </KsSkeletonWrapper>
  </section>
</template>

<style scoped>
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: clip;
}
</style>
