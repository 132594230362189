<script setup lang="ts">
import type { PropType } from "vue"

const props = defineProps({
  html: {
    type: String as PropType<string>,
    default: "",
  },
})

const route = useRoute()
const router = useRouter()

const content = computed(() => {
  const html = stringToHTML(props.html) as Element
  html.querySelector("h1")?.remove()
  return html.innerHTML
})

const ACTIVE_CLASS = "text-link--active-target"

function handleLinks(event: Event) {
  const link = (event.target as HTMLElement)?.closest("a")
  const href = link?.getAttribute("href")

  if (href?.startsWith("#")) {
    event.preventDefault()

    if (link) {
      clearActiveLinks()
      link.classList.add(ACTIVE_CLASS)
    }

    if (route.hash === href) {
      router.back()
    } else {
      route.hash
        ? router.replace({ ...route, hash: href })
        : router.push({ ...route, hash: href })
    }
  }
}

function clearActiveLinks() {
  document
    .querySelectorAll(`.${ACTIVE_CLASS}`)
    .forEach(el => el.classList.remove(ACTIVE_CLASS))
}

onBeforeRouteUpdate(({ hash }) => {
  if (!hash) {
    clearActiveLinks()
  }
})

onMounted(() => {
  const link = document.querySelector(`a[href="${route.hash}"]`)
  if (link) {
    link.classList.add(ACTIVE_CLASS)
  }
})
</script>

<template>
  <div
    v-if="content"
    class="richtext [&_.math-block]:pl-6 md:[&_.math-block]:pl-10"
    @click="handleLinks"
    v-html="content"
  />
</template>
