import gql from "graphql-tag"

export default gql`
  query ($locationId: [Int]) {
    content {
      mathTasks(query: { ParentLocationId: $locationId }) {
        edges {
          node {
            _type {
              identifier
            }
            assignmentText {
              html5
            }
            _location {
              priority
              id
              children {
                edges {
                  node {
                    content {
                      ... on SolutionContent {
                        id
                        _url
                        solutionText {
                          html5
                        }
                        _type {
                          identifier
                        }
                      }
                      ... on SuggestedSolutionContent {
                        id
                        title
                        _type {
                          identifier
                        }
                        _location {
                          children {
                            edges {
                              node {
                                priority
                                content {
                                  ... on SuggestedSolutionSectionContent {
                                    id
                                    sectionBody {
                                      html5
                                    }
                                    sectionImage {
                                      id
                                      alternativeText
                                      variation(
                                        identifier: fullwidth
                                      ) {
                                        uri
                                      }
                                    }
                                    _type {
                                      identifier
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        _type {
                          identifier
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
