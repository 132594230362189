import { ContentArticle } from "~/models/Content/ContentArticle"
import type { BaseItem } from "~/models/Content/BaseItem"
import type {
  TagsField,
  TagField,
  BaseField,
  FieldValue,
  ImageRelationField,
  MetadataField,
  RichTextField,
} from "~/models/Content/BaseField"
import type { PropOfType } from "~/models/Utilities"
import type {
  Location,
  SearchResponse,
  SearchResult,
} from "~/models/Content/Response"
import type { ContentProductPages } from "~/models/Content/ContentProductPages"
import type { SortField, SortOrder } from "~/models/Content/Sort"
import { ProductVariants } from "~/models/Content/ContentProducts"
import { ContentType } from "~/models/Content/ContentType"
import stringUtils from "~/utilities/stringUtils"
import { ContentProductPageBlocks } from "~/models/Content/ContentProductPageBlocks"

let itemIdToLog = 0

export default () => {
  const { richTextIsEmpty } = stringUtils()

  const getContentTypeIdentifier = (
    location: Location,
  ): ContentType => location.ContentInfo.ContentType._identifier

  const getCurrentVersion = (location: Location) =>
    location.ContentInfo.Content.CurrentVersion.Version

  const getFields = (location: Location) => {
    const fields = getCurrentVersion(location).Fields.field
    fields.map(field => {
      if (location.id === itemIdToLog) {
        console.log(field.fieldDefinitionIdentifier)
        console.log(field.fieldValue)
      }
    })
    itemIdToLog === location.id ? (itemIdToLog = 0) : itemIdToLog
    return fields
  }

  const getField = <T extends FieldValue>(
    location: Location,
    identifier: string,
  ) =>
    getFields(location).find(
      ({ fieldDefinitionIdentifier }) =>
        fieldDefinitionIdentifier === identifier,
    ) as BaseField<T>

  const getFieldValue = <T extends FieldValue>(
    location: Location,
    identifier: string,
    defaultValue?: T,
  ): T =>
      getField<T>(location, identifier)?.fieldValue ?? defaultValue

  const getEnhancedSelection = <T extends string>(
    location: Location,
    identifier: string,
    defaultValue?: T,
  ): T =>
      getFieldValue<T[]>(location, identifier, [])[0] ?? defaultValue

  const getRichText = (location: Location, identifier: string) => {
    const intro = getFieldValue<RichTextField>(location, identifier)
    const value = (intro?.xhtml5output || intro?.xml || "").replace(
      "<?xml version='1.0' encoding='UTF-8'?>\n",
      "",
    )
    return richTextIsEmpty(value) ? "" : value
  }

  const getThumbnail = (
    location: Location,
  ): { src: string; alt: string } => {
    const src =
      location.ContentInfo.Content.CurrentVersion.Version.Thumbnail
        ?.resource
    return src && !src.includes("ezplatformadmin")
      ? {
        src,
        alt:
            getFieldValue<ImageRelationField>(location, "thumbnail")
              ?.alternativeText ?? "",
      }
      : {
        src:
            getFieldValue<MetadataField>(location, "metadata")
              ?.image ?? "",
        alt: "",
      }
  }

  const getTagKeywords = <T extends string>(
    location: Location,
    identifier: string,
  ): T[] =>
    getFieldValue<TagsField>(location, identifier, []).flatMap(
      (tag: TagField) => Object.values(tag.keywords),
    ) as T[]

  const getIdFromHref = (
    location: Location,
    prop: PropOfType<Location, { _href: string } | undefined>,
    index = -1,
  ): number | null => {
    const value = (location[prop]?._href || "")
      .split("/")
      .slice(index)[0]
    return value ? Number(value) : null
  }

  const getIdFromContentHref = (
    location: Location,
    prop: PropOfType<
      Location["ContentInfo"]["Content"],
      { _href: string } | undefined
    >,
    index = -1,
  ): number | null => {
    const value = (location.ContentInfo.Content[prop]?._href || "")
      .split("/")
      .slice(index)[0]
    return value ? Number(value) : null
  }

  const mapBaseFields = (location: Location): BaseItem => ({
    title: location.ContentInfo.Content.TranslatedName,
    locationId: location.id,
    pathString: location.pathString,
    contentId: location.ContentInfo.Content._id,
    remoteId: location.ContentInfo.Content._remoteId,
    publishedDate: location.ContentInfo.Content.publishedDate,
    mainLocationId:
      getIdFromContentHref(location, "MainLocation") ?? location.id,
    parentLocationId: getIdFromHref(location, "ParentLocation"),
    contentTypeIdentifier: getContentTypeIdentifier(location),
    shortTitle: getFieldValue<string>(location, "short_title", ""),

    intro: getRichText(location, "intro"),
    label: getEnhancedSelection<string>(location, "label"),
    thumbnail: getThumbnail(location).src,
    thumbnailAlt: getThumbnail(location).alt,
    sortField: <SortField>location.sortField?.toLowerCase(),
    sortOrder: <SortOrder>location.sortOrder?.toLowerCase(),
    children: [],
  })

  const mapProduct = (location: Location): ProductVariants => ({
    ...mapBaseFields(location),
    ibexaTitle: getFieldValue<string>(location, "ibexa_title"),
    shortTitle: getFieldValue<string>(location, "short_title"),
    titleHtml: getFieldValue<string>(location, "title_html"),
    ean: getFieldValue<string>(location, "ean"),
    pensumFor: getFieldValue<string>(location, "pensum_for", ""),
    isReleased: getFieldValue<boolean>(location, "is_released"),
    _location: {
      id: location.id,
      pathString: location.pathString,
    },
  })

  const mapProductPage = (
    location: Location,
  ): ContentProductPages => ({
    ...mapBaseFields(location),
    header: {
      html5: getRichText(location, "header"),
    },
    groupSalesPitch: {
      html5: getRichText(location, "group_sales_pitch"),
    },
    theme: getTagKeywords(location, "theme")[0],
  })

  const mapContentProductPageBlocks = (
    location: Location,
  ): ContentProductPageBlocks => ({
    ...mapBaseFields(location),
    body: getFieldValue(location, "body", ""),
    header: getFieldValue(location, "header", ""),
    image: getFieldValue<ImageRelationField>(location, "image"),
    imageText: getFieldValue(location, "image_text", ""),
    blockOrientation: getTagKeywords(
      location,
      "block_orientation",
    )[0],
  })

  const mapContentArticle = (location: Location): ContentArticle => ({
    ...mapBaseFields(location),
    titleHtml: getFieldValue(location, "titleHtml", ""),
  })

  const mapContent = (location: Location) => {
    const identifier = getContentTypeIdentifier(location)
    if (location.id === itemIdToLog) {
      debugger
    }
    switch (identifier) {
      case ContentType.Product:
        return mapProduct(location)
      case ContentType.ProductPage:
        return mapProductPage(location)
      case ContentType.ProductPageBlock:
        return mapContentProductPageBlocks(location)
      case ContentType.Article:
        return mapContentArticle(location)
      default:
        return mapBaseFields(location)
    }
  }

  const mapContents = (result: SearchResult) =>
    mapLocations(result).map(mapContent)

  const mapLocations = (result?: SearchResult) =>
    (result?.searchHits?.searchHit ?? []).map(
      hit => hit.value.Location,
    )

  const mapResult = (response: SearchResponse) => response.View.Result

  return {
    getRichText,
    getEnhancedSelection,
    getContentTypeIdentifier,
    getFieldValue,
    mapBaseFields,
    mapLocations,
    mapResult,
    mapContents,
    mapContent,
  }
}
