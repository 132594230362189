<script setup lang="ts">
import type { PropType } from "vue"
import type { Article } from "~/types/article"
import { toDisplayLanguage } from "../../utilities/videoUtils"

const props = defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const video = ref<HTMLVideoElement | null>(null)
const isPlaying = ref(false)
const videoLoaded = ref(false)
const showTime = ref(true)

const route = useRoute()

const onLoad = () => {
  videoLoaded.value = true
}

const convertToDigits = (x: number) => (x < 10 ? `0${x}` : x)

const getMinutes = () => {
  if (props.data.video == null) return
  const sec = parseInt(props.data.video.metadata.duration, 10)
  const minutes = Math.floor(sec / 60)
  const seconds = sec - minutes * 60

  return `${convertToDigits(minutes)}:${convertToDigits(seconds)}`
}

function getVideo(data: any) {
  if (!data) return
  const url = data?.blob
    ? URL.createObjectURL(data.blob)
    : data.elementURI

  return url
}
const toggleVideoPlay = () => {
  if (video.value && (!isPlaying.value || video.value.paused)) {
    if (showTime.value) {
      showTime.value = false
    }
    video.value.play()
    isPlaying.value = true
  } else {
    video.value?.pause()
    isPlaying.value = false
  }
}

function convertToSeconds(time: string) {
  return time
    .split(":")
    .reverse()
    .reduce(
      (prev, curr, i) =>
        prev + (curr as unknown as number) * Math.pow(60, i),
      0,
    )
}
function onPlay(time: string) {
  const seconds = convertToSeconds(time)

  if (!video.value) return

  video.value.currentTime = seconds
  isPlaying.value = true
  showTime.value = false
  video.value.play()
}

const baseUrl = import.meta.env.VITE_IBEXA_BASEURL

const backgroundColor = useBackgroundColor()

watchEffect(() => {
  if (!route.fullPath?.includes("/sok")) {
    backgroundColor.value = "var(--theme-dark)"
  }
})
</script>

<template>
  <div
    :id="`template-${data.id}`"
    class="flex flex-col items-center justify-start w-full gap-10 overflow-y-scroll text-white bg-darker"
  >
    <div class="w-[80%] max-w-[100em] flex-col gap-6 py-32">
      <UArticleHeader
        :data="data"
        is-dark
        class="flex flex-col items-start justify-center md:px-20 lg:px-40"
      />
      <URichText
        class="pt-8 text-lg md:px-20 lg:px-40 lg:text-xl xl:mb-12"
        :html="data.intro?.html"
      />
      <div class="relative self-center mt-10 w-fit">
        <UButtonNew
          v-if="!isPlaying && videoLoaded"
          circle
          theme="cta"
          variant="filled"
          class="absolute inset-0 z-50 m-auto"
          @click="toggleVideoPlay"
          ><template #prepend>
            <UIcon name="play" size="lg" class="p-2"
          /></template>
        </UButtonNew>
        <span
          v-if="showTime && !getMinutes()?.includes('NaN')"
          class="absolute hidden p-2 text-2xl font-extrabold rounded-lg national-2 left-4 top-4 bg-darker text-platform-white md:block"
          >{{ getMinutes() }}</span
        >
        <span class="sr-only">minutter lengde</span>
        <video
          v-if="data.video"
          ref="video"
          preload="auto"
          :captions="data.video.captions"
          controls
          controlsList="nodownload"
          :poster="
            data.video.img?.src ? data.video.img.src : undefined
          "
          crossorigin="anonymous"
          playsinline
          :src="getVideo(data.video?.metadata)"
          tabindex="0"
          class="mb-10 h-60 max-w-[80vw] rounded-2xl object-cover sm:h-full"
          @loadeddata="onLoad"
          @play="isPlaying = true"
          @pause="isPlaying = false"
        >
          <track
            v-for="caption in data.video.captions"
            :key="caption.captionFile.uri"
            :src="baseUrl + caption.captionFile.uri"
            :srclang="caption.language"
            :label="toDisplayLanguage(caption.language)"
          />
        </video>
      </div>
      <div class="flex max-w-[80vw] flex-wrap gap-4 self-start">
        <UButtonNew
          v-for="timestamp in data?.video?.timestamps"
          :key="timestamp.id"
          theme="translucent-light"
          pill
          size="sm"
          variant="filled"
          @click="onPlay(timestamp.time)"
        >
          {{ timestamp.time }} {{ timestamp?.description }}
        </UButtonNew>
      </div>
    </div>
  </div>
</template>
