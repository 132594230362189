import { useToastStore } from "~/store/toast"

const id = crypto.randomUUID()

export const useProductPrice = () => {
  const productStore = useProductVariantsStore()
  const addToast = useToastStore().addToast

  const price = computed(() => {
    const activeVariant = productStore.activeVariant
    if (activeVariant?.eCommerce) {
      return activeVariant?.eCommerce?.price
    } else {
      const ecommercePrice = activeVariant
        ? productStore.eCommerce.find(e =>
            e.title.includes(activeVariant.name),
          )?.price
        : undefined

      if (activeVariant && ecommercePrice === undefined) {
        addToast({
          type: "error",
          message: `Vi klarte ikke å hente produktinformasjonen for ${activeVariant.name}.`,
          id,
        })
      }

      return ecommercePrice
    }
  })

  return price
}
