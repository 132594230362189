<script setup lang="ts">
import { debounce } from "lodash-es"
import type { PropType } from "vue"
import type { Article } from "~/types/article"
import { getColorClassNameFromScheme } from "~/utilities/colors"
import { toDisplayLanguage } from "../../utilities/videoUtils"

const props = defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const baseUri = import.meta.env.VITE_IBEXA_BASEURL
const store = useProductContentStore()
const route = useRoute<"article">()

const rootEl = ref<HTMLDivElement | null>()
const scrollerEl = ref<HTMLDivElement | null>(null)

const currentSubject = computed(() =>
  store.subjects.find(i =>
    i.href.includes(String(route.params.subject)),
  ),
)

const slides = computed(() => currentSubject.value?.introPages ?? [])
const url = store.articles?.find(i => i.audio)?.href
const firstAudioArticleUrl = ref(
  url?.substring(url.lastIndexOf("/") + 1),
)
const withImage = ref(!!props.data.img?.src?.length)

const backgroundVal = withImage.value
  ? ""
  : getColorClassNameFromScheme(props.data.colorTag as string, ["bg"])
const textVal = withImage.value
  ? ""
  : getColorClassNameFromScheme(props.data.colorTag as string, [
    "text",
  ])

const getStyle = computed(() => {
  if (withImage.value) {
    return {
      backgroundImage: `linear-gradient(0deg, rgba(18, 11, 43, 0.5), rgba(18, 11, 43, 0.5)), url('${props.data.img?.src}')`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      viewTimeline: "--article-slide-index x",
    }
  }
})

function nextSlide() {
  scrollerEl.value?.scrollBy({ left: innerWidth, behavior: "smooth" })
}

function prevSlide() {
  scrollerEl.value?.scrollBy({
    left: innerWidth * -1,
    behavior: "smooth",
  })
}

function toSlide(n: number) {
  scrollerEl.value?.scrollTo({
    left: innerWidth * n,
    behavior: "smooth",
  })
}

function getSlideIndex() {
  if (scrollerEl.value == null) return 0
  const { scrollLeft, clientWidth } = scrollerEl.value
  return Math.round(scrollLeft / clientWidth)
}

const animationTimelineSupported = ref(
  CSS.supports("animation-timeline", "--article-slide-index"),
)
// Fallback for when scroll animations are not supported
const activeSlideIndexFallbackValue = ref(0)
const activeSlideFallbackTracker = debounce(
  () => {
    activeSlideIndexFallbackValue.value = getSlideIndex()
  },
  200,
  { leading: true },
)

onMounted(() => {
  rootEl.value?.focus()

  // Fallback for when scroll animations are not supported
  if (!animationTimelineSupported.value) {
    scrollerEl.value?.addEventListener(
      "onscrollend" in scrollerEl.value ? "scrollend" : "scroll",
      activeSlideFallbackTracker,
    )
  }
})

onUnmounted(() => {
  // Fallback for when scroll animations are not supported
  if (!animationTimelineSupported.value) {
    scrollerEl.value?.removeEventListener(
      "onscrollend" in scrollerEl.value ? "scrollend" : "scroll",
      activeSlideFallbackTracker,
    )
  }
})

// These two render functions are used to support navigation in both Safari and other browsers
const renderBackButton = computed(() => {
  if (animationTimelineSupported.value) {
    return true
  } else {
    return activeSlideIndexFallbackValue.value > 0
  }
})

const renderNextButton = computed(() => {
  if (animationTimelineSupported.value) {
    return true
  } else {
    return activeSlideIndexFallbackValue.value < slides.value.length
  }
})
</script>

<template>
  <div
    ref="rootEl"
    class="grid timeline-scope"
    :style="{
      '--timeline-scope': `--article-slide-index, ${slides
        .map((_, index) => `--article-slide-${index}`)
        .join(', ')}`,
    }"
    style="outline: none"
    tabIndex="-1"
    @keyup.right="
      event => {
        if (getSlideIndex() < slides.length) {
          event.stopPropagation()
          nextSlide()
        }
      }
    "
    @keyup.left="
      event => {
        if (getSlideIndex() > 0) {
          event.stopPropagation()
          prevSlide()
        }
      }
    "
  >
    <template v-if="slides.length > 0">
      <div class="fixed left-0 right-0 z-10 u-grid top-2">
        <div
          class="flex justify-center col-start-2 gap-1 col-end-18 md:col-start-4 md:col-end-16 md:gap-6"
        >
          <button
            class="h-2 rounded-full w-60"
            :class="{
              'bg-black': activeSlideIndexFallbackValue !== 0,
              'opacity-30': activeSlideIndexFallbackValue !== 0,
            }"
            :style="{
              ...(activeSlideIndexFallbackValue === 0 && {
                'backdrop-filter':
                  'grayscale(1) contrast(1000) invert()',
              }),
              animation: 'linear opacity 1s',
              'animation-fill-mode': 'both',
              'animation-timeline': `--article-slide-index`,
            }"
            @click="toSlide(0)"
          />
          <button
            v-for="(_, index) in slides"
            :key="index"
            class="h-2 rounded-full w-60"
            :class="{
              'bg-black': activeSlideIndexFallbackValue !== index + 1,
              'opacity-30':
                activeSlideIndexFallbackValue !== index + 1,
            }"
            :style="{
              ...(activeSlideIndexFallbackValue === index + 1 && {
                'backdrop-filter':
                  'grayscale(1) contrast(1000) invert()',
              }),
              animation: 'linear opacity 1s',
              'animation-fill-mode': 'both',
              'animation-timeline': `--article-slide-${index}`,
            }"
            @click="toSlide(index + 1)"
          />
        </div>
      </div>

      <UButtonNew
        v-if="renderBackButton"
        circle
        variant="filled"
        theme="white"
        class="fixed left-6 top-[50%] z-10 -translate-y-1/2 transform"
        :style="{
          animation: 'linear hidden 1s',
          'animation-fill-mode': 'both',
          'animation-timeline': `--article-slide-index`,
        }"
        @click="prevSlide()"
      >
        <template #prepend>
          <u-icon name="arrow-left" />
        </template>
      </UButtonNew>

      <UButtonNew
        v-if="renderNextButton"
        circle
        variant="filled"
        theme="white"
        class="fixed right-6 top-[50%] z-10 -translate-y-1/2 transform"
        :style="{
          animation: 'linear hidden 1s',
          'animation-fill-mode': 'both',
          'animation-timeline': `--article-slide-${
            slides.length - 1
          }`,
        }"
        @click="nextSlide()"
      >
        <template #prepend>
          <u-icon name="arrow-right" />
        </template>
      </UButtonNew>
    </template>

    <div
      :ref="el => (scrollerEl = el as HTMLDivElement)"
      class="no-scrollbar grid w-full snap-x snap-mandatory auto-cols-[100%] grid-flow-col !grid-rows-1 overflow-auto scroll-smooth"
    >
      <article
        class="u-grid u-article relative snap-center snap-always pt-0 text-black md:place-items-center md:text-platform-paper <md:bg-platform-paper <md:!bg-none"
        :style="getStyle"
        :class="[
          {
            'md:auto-rows-auto':
              data.subjectPageTitlePosition === 'bottom' ||
              data.subjectPageTitlePosition === 'middle',
          },
          backgroundVal,
        ]"
      >
        <div
          v-if="!withImage"
          class="col-start-1 col-end-19 h-[66cqh] w-full md:hidden"
          :class="backgroundVal"
        />
        <img
          v-if="withImage"
          :src="data.img?.src"
          class="col-start-1 col-end-19 h-[66cqh] w-full object-cover md:hidden"
        />
        <h1
          class="order-1 col-start-3 col-end-18 font-national2compressed text-6xl font-medium uppercase text-white @md:order-none <md:pt-[var(--grid-col-width)/2]"
        >
          Intro: {{ data.title }}
        </h1>
      </article>
      <article
        v-for="(page, index) in slides"
        :key="index"
        class="py-16 u-grid snap-center snap-always place-content-center place-items-center"
        :style="`view-timeline: --article-slide-${index} x`"
        :class="
          page.colorTheme && page.template === 'Title'
            ? getColorClassNameFromScheme(
                page.colorTheme,
                ['bg', 'text'],
                { bwText: true },
              )
            : 'bg-platform-paper text-black'
        "
      >
        <template v-if="page.template === 'Image with text'">
          <img
            v-if="page.image"
            :src="page.image.variation.uri"
            :alt="page.image.alternativeText"
            class="col-start-2 col-end-18 md:col-start-4 md:col-end-9"
          />

          <div
            class="col-start-2 richtext col-end-18 md:col-start-11 md:col-end-16 md:text-3xl"
            v-html="page.richtext.html5"
          />
        </template>

        <template v-else-if="page.template === 'Text with image'">
          <img
            v-if="page.image"
            :src="page.image.variation.uri"
            :alt="page.image.alternativeText"
            class="col-start-2 col-end-18 md:order-2 md:col-start-11 md:col-end-16"
          />

          <div
            class="col-start-2 richtext col-end-18 md:order-1 md:col-start-4 md:col-end-9 md:text-3xl"
            v-html="page.richtext.html5"
          />
        </template>

        <template v-else-if="page.template === 'Title'">
          <div
            class="col-start-2 text-3xl richtext col-end-18 md:col-start-5 md:col-end-15 md:text-6xl"
            v-html="page.richtext.html5"
          />
        </template>

        <template v-else>
          <div
            class="richtext col-start-2 col-end-18 md:col-start-5 md:col-end-15 md:text-3xl <md:order-2"
            v-html="page.richtext.html5"
          />

          <img
            v-if="page.image"
            :src="page.image.variation.uri"
            :alt="page.image.alternativeText"
            class="col-start-1 col-end-20 max-h-[60vh] md:col-start-4 md:col-end-16 <md:order-1"
          />

          <video
            v-else-if="page.video"
            class="col-start-1 col-end-20 md:col-start-4 md:col-end-16 <md:order-1"
            autoplay
            muted
            controls
            controlslist="nodownload"
            preload="auto"
            :captions="page.video.captions"
            :poster="page.video.img.src || page.video.metadata.image"
            :src="page.video.metadata.elementURI"
          >
            <track
              v-for="caption in page.video.captions"
              :key="caption.captionFile.uri"
              :src="baseUri + caption.captionFile.uri"
              :srclang="caption.language"
              :label="toDisplayLanguage(caption.language)"
            />
          </video>
        </template>
      </article>
    </div>
  </div>
</template>

<style>
.timeline-scope {
  timeline-scope: var(--timeline-scope);
}

@keyframes hidden {
  entry 0% {
    opacity: 1;
  }
  entry 10% {
    opacity: 0;
  }
  exit 90% {
    opacity: 0;
  }
  exit 100% {
    opacity: 1;
  }
}

@keyframes opacity {
  entry 0% {
    opacity: 0.3;
  }
  entry 100% {
    opacity: 1;
  }
  exit 0% {
    opacity: 1;
  }
  exit 100% {
    opacity: 0.3;
  }
}
</style>
