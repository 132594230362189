<script setup lang="ts">
import type { PropType } from "vue"
import type { Subject } from "~/types/subject"
import type { Folder } from "~/types/folder"

interface MediaTypes {
  i: string
  count: number
  text: string
}

defineProps({
  data: {
    type: Object as PropType<Subject | Folder>,
    default: () => {},
  },
  hasColorSchema: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

/**
 * @description Media types
 * @param ({Subject} | {Folder}) item
 * */
function mediaTypes(item: Subject | Folder): MediaTypes[] {
  return [
    {
      i: "article",
      count: item.mediatypes.articles?.count,
      text:
        item.mediatypes.articles?.count === 1
          ? "artikkel"
          : "artikler",
    },
    // {
    //   i: 'user-headphones',
    //   count: 2,
    //   text: 'podkaster'
    // },
    {
      i: "video",
      count: item.mediatypes.videos?.count,
      text: item.mediatypes.videos?.count === 1 ? "video" : "videoer",
    },
    {
      i: "medal",
      count: item.mediatypes.quizes?.count,
      text:
        item.mediatypes.quizes?.count === 1
          ? "øvingsoppgave"
          : "øvingsoppgaver",
    },
  ]
}
</script>

<template>
  <ul class="flex gap-2 xl:h-28 xl:flex-col <md:gap-1.5">
    <li
      v-for="(el, k) in mediaTypes(data)"
      :key="k"
      :class="[
        { '!hidden': !el.count },
        {
          'fill-white text-white': !hasColorSchema,
        },
      ]"
      class="flex w-fit items-center gap-2 text-xl duration-200 xl:gap-4"
    >
      <u-icon :name="el.i" size="lg" class="text-inherit" />
      <div class="text-nav-element flex text-[18px] text-inherit">
        <span>{{ el.count }}&nbsp;</span>
        <span class="hidden xl:inline">{{ el.text }}</span>
      </div>
    </li>
  </ul>
</template>

<style scoped lang="postcss">
.text-nav-element span {
  @apply font-national2 text-[18px];
}

.text-nav-element {
  @apply font-national2 text-[22px] font-normal not-italic;
}
</style>
