<script setup>
const backgroundColor = useBackgroundColor()
backgroundColor.value = "var(--theme-lightest)"
</script>

<template>
  <div
    class="my-[5%] flex w-full flex-col items-center justify-center gap-14"
  >
    <UPlatformDescription />
    <UProductList />
  </div>
</template>

<route lang="yaml">
name: hjem
layout: default
</route>
