import { MathTask } from "~/types/article"
import { ArticleChildrenMathTaskNode } from "~/types/ibexa.article"

export default (
  articleId: number,
  edges?: { node: ArticleChildrenMathTaskNode }[],
): MathTask | undefined => {
  if (!edges || edges.length === 0) return undefined

  const assignments: MathTask["assignments"] = []

  edges.forEach(e => {
    const { content } = e.node
    if (content._type?.identifier === "math_task") {
      assignments.push({
        id: e.node.id,
        text: content.assignmentText?.html5 ?? "",
        priority: e.node.priority,
      })
    }
  })

  return {
    articleId,
    assignments: assignments.sort((a, b) => a.priority - b.priority),
  }
}
