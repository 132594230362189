<script lang="ts" setup>
import { PropType } from "vue"
import { FormattedSuggestedSolutionSection } from "~/hooks/math-task"

const props = defineProps({
  sections: {
    type: Array as PropType<FormattedSuggestedSolutionSection[]>,
    required: false,
    default: () => [],
  },
  customClass: {
    type: String,
    required: false,
    default: "",
  },
})

const containerClass = `mb-6 mt-2 font-national2 flex flex-col gap-4 rounded bg-[--color-green-lightest] px-7 py-4 lg:px-10 lg:py-7 ${props.customClass}`
</script>
<template>
  <div
    v-for="section in sections"
    :key="section.id"
    :class="containerClass"
  >
    <u-rich-text
      :html="section.sectionBody"
      class="[&>*]:font-national2"
    />
    <img
      v-if="
        section?.sectionImage && section.sectionImage.variation?.uri
      "
      :src="section.sectionImage.variation?.uri"
      :alt="section.sectionImage.alternativeText"
      class="max-w-[395px] object-cover"
    />
  </div>
  <div v-if="sections.length === 0" :class="containerClass">
    Mangler løsningsforlag.
  </div>
</template>
