import { useToastStore } from "./../../store/toast"
import type { EcommerceProduct } from "~/types/productData.d"

const toastId = crypto.randomUUID()

export async function getProductData(): Promise<EcommerceProduct[]> {
  const addToast = useToastStore().addToast

  const productInfo = await api.ecommerce.get(
    "/rest/products/?productSource=CRYSTALLIZE_KUNNE",
  )

  if (!productInfo.data) {
    addToast({
      type: "error",
      message:
        "Vi klarte ikke å hente produktinformasjonen for produktet",
      id: toastId,
    })
    return []
  }

  const products = productInfo.data.products.map((product: any) => ({
    name: product.name,
    title: product.title,
    description: product.description,
    ean: product.ean,
    imageUrl: product.imageUrl,
    imageDescription: product.imageDescription,
    price: product.price,
    currency: product.currency,
  }))

  return products
}
