<script setup lang="ts">
import type { PropType } from "vue"
import type { Article } from "~/types/article"

const props = defineProps({
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const imageSize = computed(() => {
  switch (props.data.imageSize) {
    case "Small":
      return 4

    case "Medium":
      return 6

    case "Large":
    default:
      return 8
  }
})

const textClasses = computed(() => {
  const size =
    imageSize.value <= 4 ? imageSize.value + 1 : imageSize.value
  const start = size + 2
  const end = 18

  return `col-start-2 col-end-18 @md:col-start-${start} @md:col-end-${end} @lg:col-start-${
    start + 1
  } @lg:col-end-${end - 1} @2xl:col-start-${
    start + 1
  } @2xl:col-end-${end}`
})

const imageClasses = computed(() => {
  const end = imageSize.value + 2

  return `col-start-1 col-end-19 @md:col-start-1 @md:col-end-${end}`
})

defineExpose({
  SIDENOTE_OFFSET: 1,
})
</script>

<template>
  <article
    :id="`template-${data.id}`"
    class="u-grid u-article"
    :class="{
      [`bg-${data.bg} text-${data.color}`]: data.bg,
    }"
  >
    <UArticleHeader
      :data="data"
      class="order-1 h-fit break-words pt-[var(--grid-col-width)] @md:order-none @md:row-start-1"
      :class="textClasses"
    />

    <u-article-image
      :data="data"
      class="u-article__image order-2 @md:sticky @md:top-0 @md:order-none @md:row-span-5"
      :class="imageClasses"
      :default-picture-frame-colors="true"
    />

    <div
      class="order-3 flex h-fit flex-col gap-8 bg-white p-8 @md:order-none @md:row-start-2 lg:p-12"
      :class="textClasses"
    >
      <section class="flex flex-col gap-8 break-words">
        <u-rich-text
          class="[&>*]:font-national2 [&>*]:text-[32px] [&>*]:font-bold"
          :class="textClasses"
          :html="data.intro?.html"
        />
        <u-rich-text
          v-if="data.body"
          class="[&>*]:font-national2"
          :html="data.body.html"
        />
        <UMathTaskAssignments v-if="data.mathTask" />
      </section>
      <UMathTaskActions v-if="data.mathTask" />
    </div>
  </article>
</template>
