<script lang="ts" setup>
import { Ref, ref, computed } from "vue"
import { useAudioStore } from "../../store/audio"

const audioStore = useAudioStore()

const audio: Ref<HTMLAudioElement | null> = ref(null)

const currentTrack = computed<
  ReturnType<typeof audioStore.playList.find>
>(() => audioStore.playList.find(i => i.active))

const fullscreen: Ref<boolean> = ref(false)
const progress: Ref<number> = ref(0)

export type ButtonState = "pause" | "play"
const buttonState: Ref<ButtonState> = ref("pause")

type PlayerSizeStatus = "expand-diagonal" | "minimize"
const playerSizeStatus: Ref<PlayerSizeStatus> = ref("expand-diagonal")

const handlePlay = () => {
  if (audio.value) {
    if (audio.value.paused) {
      buttonState.value = "pause"
      audio.value.play()
    } else {
      buttonState.value = "play"
      audio.value.pause()
    }
  } else buttonState.value = "pause"
}

const handleClosePlayer = () => {
  buttonState.value = "pause"
  playerSizeStatus.value = "expand-diagonal"
  audioStore.togglePlayer()
}

const handlePlayerSize = () => {
  if (playerSizeStatus.value === "expand-diagonal") {
    playerSizeStatus.value = "minimize"
  } else playerSizeStatus.value = "expand-diagonal"
  fullscreen.value = !fullscreen.value
}

const onSliderEvent = (t: number) => {
  if (audio.value) {
    audio.value.currentTime = t
    currentTime.value = audio.value.currentTime
  }
}

const onTimeUpdate = () => {
  if (audio.value && audioStore.playListSubject?.playTime) {
    currentTime.value = audio.value.currentTime
    progress.value =
      (currentTime.value * 100) / audioStore.playListSubject?.playTime
  }
}

const updateMeta = async () => {
  duration.value = audio.value?.duration ?? 0
  currentTime.value = audio.value?.currentTime ?? 0

  if (!audioStore.activePlayer) {
    audioStore.togglePlayer()
  }
  buttonState.value = "pause"
  audio.value?.play()
}

const handleSkip = (seconds: number) => {
  if (audio.value) {
    audio.value.currentTime += seconds
    currentTime.value = audio.value.currentTime
  }
}

const handleSpeed = (speed: number) => {
  if (audio.value) audio.value.playbackRate = speed
}

const duration: Ref<number> = ref(0)
const currentTime: Ref<number> = ref(0)
</script>
<template>
  <section
    v-if="
      audioStore.activePlayer &&
      useProductVariantsStore().activeVariant
    "
    class="fixed left-0 z-50 flex items-center justify-center w-full bottom-28"
    :class="{
      '!bottom-0 h-full ': fullscreen,
      '!bottom-32':
        !useProductContentStore().activeArticle &&
        !useLicenceStore().activeLicence,
      '!bottom-10':
        !useProductContentStore().activeArticle &&
        useLicenceStore().activeLicence,
    }"
  >
    <div
      class="relative flex w-[90%] items-center justify-start gap-10 rounded-lg bg-dark px-10 py-4 shadow-lg"
      :class="{
        'h-full w-full flex-col justify-end rounded-none py-10':
          fullscreen,
      }"
    >
      <div class="flex-col items-center justify-center">
        <h4
          class="w-full pt-10 text-2xl font-extrabold leading-6 text-center font-national2 text-platform-paper"
          :class="{
            'max-w-32 truncate !pt-0 text-xl sm:max-w-48 md:max-w-md lg:max-w-[15rem] xl:max-w-lg':
              !fullscreen,
          }"
        >
          {{ currentTrack?.title }}
        </h4>
        <h5 v-if="!fullscreen" class="block text-lighter lg:hidden">
          -{{ getMinutesAndSeconds(duration - currentTime) }}
        </h5>
      </div>
      <div
        class="flex items-center justify-between w-full"
        :class="{
          'flex-col': fullscreen,
          '!justify-end gap-4': !fullscreen,
        }"
      >
        <UAudioplayerControls
          :handle-play="handlePlay"
          :handle-skip="handleSkip"
          :button-state="buttonState"
          :fullscreen="fullscreen"
        />
        <UAudioSlider
          :class="{ 'hidden lg:flex': !fullscreen }"
          :current-time="currentTime"
          :handle-speed="handleSpeed"
          :max="duration"
          :fullscreen="fullscreen"
          @current-time-event="t => onSliderEvent(t)"
        />
        <div class="flex items-center justify-center gap-4">
          <!-- <UButtonNew
            variant="quite"
            theme="white"
            :class="{
              'absolute right-10 top-10': fullscreen,
              'hidden lg:flex': !fullscreen,
            }"
            class="hover:text-lighter"
            @click="handlePlayerSize()"
            ><UIcon :name="playerSizeStatus" size="lg"
          /></UButtonNew> -->
          <UButtonNew
            v-if="!fullscreen"
            variant="quite"
            theme="white"
            class="hover:text-lighter"
            @click="handleClosePlayer()"
            ><UIcon name="close" size="lg"
          /></UButtonNew>
        </div>
      </div>
      <audio
        ref="audio"
        autoplay
        :src="currentTrack?.url"
        @timeupdate="onTimeUpdate"
        @loadedmetadata="updateMeta"
      />
    </div>
  </section>
</template>
