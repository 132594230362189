<script setup lang="ts">
import type { PropType } from "vue"
import type { NavProps } from "~/types/nav.d"

const props = defineProps({
  item: {
    type: Object as PropType<NavProps["data"][0]>,
    required: true,
    default: () => ({}),
  },
  pageType: {
    type: String as PropType<string>,
    default: "subject",
  },
  licence: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})
const personalization = usePersonalizationStore()

const price = useProductPrice()
const toggleReadArticle: Ref<boolean | null> = ref(null)

const result = personalization.readHistory?.find(
  ({ id }) => id === props.item.id,
)
toggleReadArticle.value = result?.done ?? false
const totalArticlesDone = computed(() => {
  return personalization.readHistory?.filter(
    i => i.done && props.item.id === i.subjectId,
  ).length
})

const chapterStatus = computed(() => {
  if (!props.item.totalChildren || totalArticlesDone.value === 0) {
    return "not-started"
  } else if (props.item.totalChildren > totalArticlesDone.value) {
    return "in-progress"
  } else {
    return "done"
  }
})

const articleStatus = computed(() => {
  if (
    personalization.readHistory.filter(
      psItem => psItem.done && psItem.id === props.item.id,
    ).length > 0
  ) {
    return true
  }
  return false
})

const isSubjectEmpty = computed(() => {
  const subjectContent = useProductContentStore().subjects.find(
    subject => subject.title === props.item.text,
  )

  return !!(
    subjectContent?.mediatypes.articles.count ||
    subjectContent?.mediatypes.quizes.count ||
    subjectContent?.mediatypes.videos.count
  )
})
</script>

<template>
  <div
    class="select-none border-b-2 border-white/20"
    :class="[pageType === 'subject' ? 'flex items-start ' : '']"
  >
    <!-- <div v-if="isOnline && licence" class="mt-4 ml-3">
      <u-checkbox
        v-if="!item.hidden && pageType === 'subject'"
        id="nav-mark-as-read"
        v-model="toggleReadArticle"
        :disabled="personalization.settings.autoShowProgress"
        :style="{ visibility: !result?.done && personalization.settings.autoShowProgress ? 'hidden' : 'visible' }"
      />
    </div> -->
    <router-link
      v-if="!item.hidden"
      :id="`anchor-element-${item.id}`"
      :data-pendo="
        pageType === 'product'
          ? 'subject-list-item'
          : 'article-list-item'
      "
      :to="`/produkter/${item.href
        .split('/')[1]
        .replace(/-(?!.*-)/, '/')}${
        item.href.split('/')[2] ? `/${item.href.split('/')[2]}` : ''
      }${
        item.href.split('/')[3] ? `/${item.href.split('/')[3]}` : ''
      }`"
      class="group flex grow items-start gap-x-2.5 p-3"
      :class="[
        !isSubjectEmpty && pageType === 'product'
          ? 'pointer-events-none cursor-default text-grey-40'
          : 'cursor-pointer',
        toggleReadArticle ? 'border-opacity-50' : 'border-opacity-20',
        pageType === 'product'
          ? 'hover:border-[--theme-medium] hover:text-u-contrast'
          : 'ml-5 hover:border-u-contrast hover:text-u-contrast',
        pageType === 'subject' && item.restricted
          ? 'pointer-events-none text-grey-40'
          : '',
      ]"
    >
      <span
        v-if="pageType === 'product'"
        class="align-self-baseline flex items-center justify-center rounded font-national2 text-xl leading-7"
      >
        {{ item.priority }}
      </span>
      <div class="flex h-fit w-full justify-between gap-x-2">
        <span class="font-national2 text-xl leading-7">
          {{ item.text }}
        </span>
        <span
          v-if="pageType === 'product' && licence && isOnline"
          class="ml-auto flex items-center justify-center"
        >
          <u-chapter-progress
            v-if="item.totalChildren !== 0"
            :total-articles-done="totalArticlesDone"
            :total-children="item.totalChildren"
            :chapter-status="chapterStatus"
          />
        </span>
        <span
          v-show="
            item.totalChildren !== 0 &&
            !licence &&
            pageType === 'product' &&
            price &&
            price > 0
          "
          class="text-base font-bold italic text-u-contrast"
        >
          Prøvekapittel!
        </span>
      </div>
      <div
        v-if="
          isOnline &&
          licence &&
          !item.hidden &&
          pageType === 'subject'
        "
        class="mt-1 opacity-75 group-hover:text-white group-hover:opacity-100"
      >
        <u-article-status-indicator :done="articleStatus" />
      </div>
    </router-link>
  </div>
</template>
