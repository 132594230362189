<script setup lang="ts">
const productContent = useProductContentStore()
const products = useProductVariantsStore()
const personalization = usePersonalizationStore()
const { chapterCompleted } = useChapterProgress()
const router = useRouter()
const handleNextArticleClick = async () => {
  if (productContent.activeArticle?.id) {
    personalization.addArticleToReadHistory(
      productContent.activeArticle.id,
      true,
    )
  }

  if (productContent.nextArticle?.frontendHref) {
    await router.push(productContent.nextArticle.frontendHref)
  } else if (finalChapter.value) {
    await router.push(
      `/produkter/${products.activeProduct?.productKey}/${products.activeVariant?.variantKey}`,
    )
  }
}

const finalChapter = computed(() => {
  return productContent.nextSubject === undefined
})
</script>

<template>
  <div
    class="flex items-center justify-end"
    :class="{
      'md:justify-between': chapterCompleted,
      'md:justify-start': !chapterCompleted,
    }"
  >
    <UButtonNew
      v-if="chapterCompleted"
      pill
      variant="filled"
      theme="medium"
      class="mr-6"
      @click="handleNextArticleClick"
    >
      <template #prepend>
        <u-icon name="check" />
      </template>
      <p v-if="!finalChapter">Fullfør kapittel</p>
      <p v-else>Fullfør siste kapittel</p>
    </UButtonNew>
    <UButtonNew
      v-else
      pill
      variant="filled"
      theme="medium"
      class="mr-6"
      @click="handleNextArticleClick"
    >
      <template #prepend>
        <u-icon v-if="!finalChapter" name="arrow-down" />
        <u-icon v-else name="check" />
      </template>
      <p v-if="!finalChapter">Neste kapittel</p>
      <p v-else>Avslutt siste kapittel</p>
    </UButtonNew>
    <span
      v-if="!finalChapter"
      class="hidden truncate whitespace-nowrap text-button-medium-button-text-regular md:block"
    >
      <span v-if="chapterCompleted">Neste: </span>
      {{ productContent.nextSubject?.priority }}.
      {{ productContent.nextSubject?.shortTitle }}
    </span>
  </div>
</template>
