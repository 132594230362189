<script setup lang="ts">
import { Variant } from "~/models/Content/ContentProducts"

const props = defineProps({
  product: {
    type: Object as PropType<Variant>,
    required: true,
  },
  released: {
    type: Boolean,
    default: true,
  },
})

const price = computed(
  () => props.product.variants[0]?.eCommerce?.price,
)

const priceLabel = computed(() => {
  if (price.value === 0) {
    return "0,-"
  } else if (price.value && price.value > 0) {
    return `${price.value},-`
  } else {
    return ""
  }
})

const router = useRouter()
</script>

<template>
  <div
    class="flex w-full flex-col items-center gap-7 rounded-2xl bg-platform-white font-national2 drop-shadow-xl md:gap-10 lg:flex-row"
  >
    <img
      v-if="product.variants[0].eCommerce?.imageUrl"
      :src="product.variants[0].eCommerce?.imageUrl"
      class="h-[281px] w-full cursor-pointer rounded-bl-none rounded-tl-2xl rounded-tr-2xl object-contain p-4 md:h-auto lg:w-[320px] lg:rounded-bl-2xl lg:rounded-tl-2xl lg:rounded-tr-none"
      :class="`bg-${product.theme}-lightest`"
      :alt="
        product.variants[0].eCommerce?.imageDescription ||
        `Produktillustrasjon for ${product.productTitle} `
      "
      @click="
        product.hasAtLeastOneReleasedProduct &&
          router.push(
            `/produkter/${product.productTitle
              .replaceAll(' ', '-')
              .toLowerCase()}`,
          )
      "
    />
    <div
      v-else
      class="h-[281px] w-full cursor-pointer rounded-bl-none rounded-tl-2xl rounded-tr-2xl p-4 md:h-[320px] lg:w-[320px] lg:min-w-[320px] lg:rounded-bl-2xl lg:rounded-tl-2xl lg:rounded-tr-none"
      :class="`bg-${product.theme}-lightest`"
    ></div>
    <div class="flex w-full flex-col gap-4 pb-6 pl-6 pr-6 sm:p-8">
      <h4
        class="font-national2compressed text-[32px] font-extrabold uppercase leading-10 text-[--theme-dark] md:text-5xl"
      >
        Kunne
        <span v-html="product.productTitleHtml" />
        {{ !product.productTitleHtml ? product.productTitle : null }}
      </h4>
      <p
        v-if="product.groupSalesPitch?.length > 2"
        class="pr-10 text-lg md:text-xl"
        v-html="product.groupSalesPitch"
      />
      <div
        class="flex flex-col-reverse justify-between gap-4 pt-6 md:flex-row md:pr-10"
      >
        <div class="flex flex-wrap gap-4">
          <UButtonNew
            :to="`/produkter/${product.urlFormatProductTitle}`"
            bold
            size="sm"
          >
            Les mer
            <span class="sr-only"
              >om Kunne {{ product.productTitle }}</span
            >
          </UButtonNew>
          <u-feature-flag>
            <UButtonNew
              v-if="
                !released &&
                product.urlFormatProductTitle ===
                  'matematikk-for-oekonomer'
              "
              :to="`/produkter/${product.urlFormatProductTitle}/registrer-interesse`"
              data-pendo="forside-hold-meg-oppdatert"
              variant="filled"
              theme="cta"
              size="sm"
            >
              Hold meg oppdatert
            </UButtonNew></u-feature-flag
          >
          <UButtonNew
            v-if="
              product.hasAtLeastOneReleasedProduct &&
              product.variants.some(v => !v.isFree)
            "
            size="sm"
            data-pendo="forside-kjop-tilgang"
            variant="filled"
            theme="cta"
            pill
            :to="`/produkter/${product.urlFormatProductTitle}/betaling`"
          >
            Kjøp
            <span class="hidden sm:inline">tilgang</span>
            <span class="sr-only"
              >til Kunne {{ product.productTitle }}</span
            ></UButtonNew
          >
        </div>

        <div
          v-if="product.hasAtLeastOneReleasedProduct"
          class="text-2xl font-bold text-[--theme-translucent-dark] md:justify-end md:self-center md:text-3xl"
        >
          <span class="text-[--color-dark-blue-translucent-dark]">{{
            price === 0
              ? "Gratis "
              : price && price > 0
                ? "Tilbud "
                : ""
          }}</span
          >{{ priceLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss"></style>
