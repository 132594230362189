<script setup lang="ts">
const highlightedStyle =
  "font-national2 italic text-[--theme-translucent-dark]"

const sellingPoints = [
  { icon: "align-left", text: "Les korte tekster" },
  { icon: "play", text: "Lær med video" },
  { icon: "headphones", text: "Lytt til innholdet" },
  { icon: "medal", text: "Test deg selv" },
]
</script>

<template>
  <section
    class="flex max-w-[960px] flex-col gap-8 text-center font-numeraWebRegular text-xl font-normal text-[--theme-darker] [&>p]:leading-[30px] md:[&>p]:text-2xl md:[&>p]:leading-9"
  >
    <h2
      class="w-full text-center font-national2condensed text-[40px] font-medium leading-[44px] text-[--theme-dark] md:text-[72px] md:leading-[80px]"
    >
      Kunne hjelper studenter <br />
      å forstå pensum
    </h2>
    <p>
      Er du student og vil
      <b :class="highlightedStyle">forstå</b> pensum på en
      <b :class="highlightedStyle">effektiv</b>
      og
      <b :class="highlightedStyle">oversiktlig</b>
      måte? Da bør du sjekke ut Kunne.
    </p>
    <p>
      Alle våre kurs er laget av erfarne forelesere og forfattere, i
      samarbeid med
      <em>Universitetsforlaget, Aschehougs akademiske forlag</em>.
    </p>

    <div
      class="mt-6 flex flex-wrap justify-center gap-8 md:flex-row md:gap-5 lg:justify-between lg:gap-0"
    >
      <div
        v-for="item in sellingPoints"
        :key="item.icon"
        class="flex min-w-[140px] flex-col items-center justify-center gap-4 sm:min-w-[210px] md:min-w-[275px] lg:min-w-[200px]"
      >
        <UIcon
          :name="item.icon"
          size="lg"
          class="rounded-full bg-[--theme-lighter] p-7 text-[--theme-medium] [&>svg]:h-[32px!important]"
        />
        <h3 class="font-national2 text-xl italic md:text-2xl">
          <b>{{ item.text }}</b>
        </h3>
      </div>
    </div>
  </section>
</template>
<style lang=""></style>
