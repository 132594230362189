<script setup lang="ts">
import { MathTaskAssignment } from "~/types/article"
import { keys } from "~/utilities/injection"

const props = defineProps({
  assignment: {
    type: Object as PropType<MathTaskAssignment>,
    required: true,
  },
  showSuggestions: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showAssignmentInSuggestion: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
})

const suggestedSolutionsData = inject(keys.suggestedSolution)
const hideAssignmentText =
  props.showSuggestions && !props.showAssignmentInSuggestion
</script>
<template>
  <div>
    <u-rich-text
      v-show="!hideAssignmentText"
      class="[&>*]:font-national2"
      :html="assignment.text"
    />
    <USuggestedSolutionSection
      v-if="showSuggestions"
      :custom-class="!hideAssignmentText ? 'ml-4' : ''"
      :sections="
        suggestedSolutionsData?.assignments.get(assignment.id)
          ?.suggestedSolution.sections
      "
    />
  </div>
</template>
