<script setup lang="ts">
import {
  FasitSidenote,
  SidenoteData,
} from "~/components/Sidenote/USidenoteContainer.vue"
import { Relation } from "~/types/article"
import { toDisplayLanguage } from "../../utilities/videoUtils"

defineProps({
  note: {
    type: Object as PropType<SidenoteData>,
    required: true,
  },
  titleClass: {
    type: String as PropType<string>,
    default: "",
    required: false,
  },
})

const baseUri = import.meta.env.VITE_IBEXA_BASEURL

const sidenoteLabel = (
  sidenoteType: Relation["identifier"] | FasitSidenote["identifier"],
): string => {
  switch (sidenoteType) {
    case "source":
      return "Kilde"
    case "term":
      return "Begrep"
    case "what_is":
      return "Hva er?"
    case "person":
      return "Person"
    case "solution":
      return "Fasit"
    default:
      return "Forklaring"
  }
}
</script>

<template>
  <div
    class="flex flex-col gap-8 p-10 break-words place-content-center place-self-stretch"
  >
    <header class="grid gap-2">
      <small class="text-lg font-extrabold font-national2">
        {{ sidenoteLabel(note.identifier) }}
        <slot name="sidenoteType" />
      </small>
      <h2
        :class="`font-numeraWebRegular text-4xl <md:break-all ${titleClass}`"
      >
        {{ note.title }}
      </h2>
    </header>

    <slot name="content" />

    <div class="text-xl font-national2" v-html="note.html" />

    <template v-if="note.image">
      <figure class="place-self-start">
        <img
          v-if="note.image"
          :src="note.image.variation?.uri || note.image.uri"
          :width="note.image.width"
          :height="note.image.height"
          :alt="note.image.alternativeText"
          class="h-[164px] w-auto"
        />

        <figcaption
          v-if="note.imageCaption"
          class="mt-4"
          v-text="note.imageCaption"
        />
      </figure>
    </template>
    <template v-if="note.video">
      <video
        v-if="note.video?.metadata"
        :src="note.video.metadata.elementURI"
        controlslist="nodownload"
        controls
        preload="auto"
        :captions="note.video.captions"
        :poster="note.video.img.src || note.video.metadata.image"
      >
        <track
          v-for="caption in note.video.captions"
          :key="caption.captionFile.uri"
          :src="baseUri + caption.captionFile.uri"
          :srclang="caption.language"
          :label="toDisplayLanguage(caption.language)"
        />
      </video>
    </template>
  </div>
</template>
